import {createTheme as createMuiTheme} from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
	typography: {
		useNextVariants: true,
		suppressDeprecationWarnings: true,
	},
});
const drawerWidth = defaultTheme.spacing(8);
const styles = () => ({
	root: {
		paddingLeft: drawerWidth + 10,
		[defaultTheme.breakpoints.down('md')]: {
			paddingLeft: 10,
		},
		width: `100%`,
		margin: 0,
	},
});

export default styles;
