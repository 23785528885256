/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, {memo, useRef, useState} from 'react';
import validate from 'validate.js';
import {withStyles} from '@material-ui/core/styles';
import {
	Hidden,
	Grid,
	Button,
	Divider,
	TextField,
	makeStyles,
	Card,
	CardHeader,
	CardContent,
	Typography,
} from '@material-ui/core';
import AppBarDrawer from 'common/AppBarWithDrawer/appBarDrawer';

import toastr from 'services/Alerts';
import {useAuth} from 'services/ProviderAuth/ProviderAuth';
import AuthProviderList from './components/AuthProviderList';
import constraints from './data/constraints';
import authentication from './services/authentication';

const styles = makeStyles(theme => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
	},

	icon: {
		marginRight: theme.spacing(0.5),
	},

	divider: {
		margin: 'auto',
	},

	grid: {
		marginBottom: theme.spacing(2),
	},
	actions: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

function GroupFieldsEmailPassword(props) {
	return (
		<form onKeyPress={props.handleKeyPress}>
			<Grid container direction="column" spacing={2}>
				<Grid item xs>
					<TextField
						id="email"
						autoComplete="email"
						disabled={props.performingAction}
						error={!!(props.errors && props.errors.emailAddress)}
						fullWidth
						helperText={
							props.errors && props.errors.emailAddress
								? props.errors.emailAddress[0]
								: ''
						}
						label="Endereço de e-mail"
						placeholder="email@exemplo.com"
						required
						type="email"
						variant="outlined"
						value={props.emailAddress || ''}
						InputLabelProps={{required: false}}
						onChange={props.handleEmailAddressChange}
					/>
				</Grid>

				<Grid item xs>
					<TextField
						id="password"
						autoComplete="current-password"
						disabled={props.performingAction}
						error={!!(props.errors && props.errors.password)}
						fullWidth
						helperText={
							props.errors && props.errors.password
								? props.errors.password[0]
								: ''
						}
						label="Senha"
						placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
						required
						type="password"
						variant="outlined"
						value={props.password || ''}
						InputLabelProps={{required: false}}
						onChange={props.handlePasswordChange}
					/>
				</Grid>
			</Grid>
		</form>
	);
}

function Login() {
	const classes = styles();
	const {signin, signInWithEmailAndPassword} = useAuth();
	const [emailAddress, setEmailAddress] = useState();
	const [password, setPassword] = useState();
	const [performingAction, setPerformingAction] = useState(false);
	const [errors, setErrors] = useState(null);

	const sendSignInLinkToEmail = () => {
		const error = validate(
			{
				emailAddress,
			},
			{
				emailAddress: constraints.emailAddress,
			},
		);

		if (error) {
			setErrors(error);
			return;
		}
		setPerformingAction(true);
		setErrors(null);
		authentication
			.sendSignInLinkToEmail(emailAddress)
			.then(() =>
				toastr.success(`Email para entrar enviado. Confira em ${emailAddress}`),
			)
			.catch(reason =>
				toastr.error(new Error(authentication.getErrorFirebase(reason))),
			)
			.finally(() => setPerformingAction(false));
	};

	const resetPassword = () => {
		const error = validate(
			{
				emailAddress,
			},
			{
				emailAddress: constraints.emailAddress,
			},
		);

		if (error) {
			setErrors(error);
		} else {
			setErrors(null);
			setPerformingAction(true);
			authentication
				.resetPassword(emailAddress)
				.then(() => {
					toastr.success(
						`Link para redefinir a Senha enviada ao email ${emailAddress}`,
					);
				})
				.catch(reason => {
					toastr.success(authentication.getErrorFirebase(reason));
				})
				.finally(() => {
					setPerformingAction(false);
				});
		}
	};

	const signInWithAuthProvider = provider => {
		setPerformingAction(true);
		authentication
			.signInWithAuthProvider(provider)
			.then(user => {
				const {displayName} = user;
				const {email} = user;
				toastr.success(`Login realizado como ${displayName || email}`);
			})
			.catch(reason => {
				toastr.success(authentication.getErrorFirebase(reason));
			})
			.finally(() => {
				setPerformingAction(false);
			});
	};

	const handleKeyPress = event => {
		if (!emailAddress && !password) {
			return;
		}
		const {key} = event;
		if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
			return;
		}
		if (key === 'Enter') {
			if (emailAddress && !password) {
				sendSignInLinkToEmail();
			} else {
				signInWithEmailAndPassword(emailAddress, password);
			}
		}
	};

	const handleEmailAddressChange = event => {
		event.preventDefault();
		setEmailAddress(event?.target?.value || emailAddress);
	};

	const handlePasswordChange = event => {
		event.preventDefault();
		setPassword(event?.target?.value || password);
	};

	return (
		<div>
			<AppBarDrawer />
			<section className="login-card">
				<Card variant="outlined" className="card">
					<CardHeader title="Faça login em sua conta" />

					<CardContent>
						<Hidden xsDown>
							<Grid container direction="row">
								<Grid item xs={4}>
									<AuthProviderList
										performingAction={performingAction}
										onAuthProviderClick={signin}
									/>
								</Grid>

								<Grid item xs={1}>
									<Divider className={classes.divider} orientation="vertical" />
								</Grid>

								<Grid item xs={7}>
									<GroupFieldsEmailPassword
										performingAction={performingAction}
										handleKeyPress={handleKeyPress}
										handleEmailAddressChange={handleEmailAddressChange}
										handlePasswordChange={handlePasswordChange}
										password={password}
										emailAddress={emailAddress}
										errors={errors}
									/>
								</Grid>
							</Grid>
						</Hidden>

						<Hidden smUp>
							<AuthProviderList
								gutterBottom
								performingAction={performingAction}
								onAuthProviderClick={signin}
							/>
							<GroupFieldsEmailPassword
								performingAction={performingAction}
								handleKeyPress={handleKeyPress}
								handleEmailAddressChange={handleEmailAddressChange}
								handlePasswordChange={handlePasswordChange}
								password={password}
								emailAddress={emailAddress}
								errors={errors}
							/>
						</Hidden>
						<Grid
							container
							direction="row"
							justifyContent="flex-end"
							className={classes.actions}
							spacing={2}
						>
							<Grid item>
								<Button
									color="primary"
									disabled={!emailAddress || performingAction}
									variant="outlined"
									onClick={() => resetPassword()}
									size="medium"
								>
									Resetar Senha
								</Button>
							</Grid>
							<Grid item>
								<Button
									color="primary"
									disabled={!emailAddress || !password || performingAction}
									variant="contained"
									onClick={() =>
										signInWithEmailAndPassword(emailAddress, password)
									}
									size="medium"
								>
									Entrar
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</section>
		</div>
	);
}

export default memo(withStyles(styles)(Login));
