export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_ANALYSIS_BEGIN = 'GET_USERS_ANALYSIS_BEGIN';
export const GET_USERS_ANALYSIS_SUCCESS = 'GET_USERS_ANALYSIS_SUCCESS';
export const GET_USERS_ANALYSIS_FAILURE = 'GET_USERS_ANALYSIS_FAILURE';
export const GET_USERS_REGISTER_BEGIN = 'GET_USERS_REGISTER_BEGIN';
export const GET_USERS_REGISTER_SUCCESS = 'GET_USERS_REGISTER_SUCCESS';
export const GET_USERS_REGISTER_FAILURE = 'GET_USERS_REGISTER_FAILURE';
export const GET_ANALYSIS_TIME_BEGIN = 'GET_ANALYSIS_TIME_BEGIN';
export const GET_ANALYSIS_TIME_SUCCESS = 'GET_ANALYSIS_TIME_SUCCESS';
export const GET_ANALYSIS_TIME_FAILURE = 'GET_ANALYSIS_TIME_FAILURE';

export const getUsersBegin = () => ({
	type: GET_USERS_BEGIN,
});

export const getUsersSuccess = data => ({
	type: GET_USERS_SUCCESS,
	payload: {data},
});

export const getUsersFailure = error => ({
	type: GET_USERS_FAILURE,
	payload: {error},
});

export const getUsersAnalysisBegin = () => ({
	type: GET_USERS_ANALYSIS_BEGIN,
});

export const getUsersAnalysisSuccess = data => ({
	type: GET_USERS_ANALYSIS_SUCCESS,
	payload: {data},
});

export const getUsersAnalysisFailure = error => ({
	type: GET_USERS_ANALYSIS_FAILURE,
	payload: {error},
});

export const getUsersRegisterBegin = () => ({
	type: GET_USERS_REGISTER_BEGIN,
});

export const getUsersRegisterSuccess = data => ({
	type: GET_USERS_REGISTER_SUCCESS,
	payload: {data},
});

export const getUsersRegisterFailure = error => ({
	type: GET_USERS_REGISTER_FAILURE,
	payload: {error},
});

export const getAnalysisTimeBegin = () => ({
	type: GET_ANALYSIS_TIME_BEGIN,
});

export const getAnalysisTimeSuccess = data => ({
	type: GET_ANALYSIS_TIME_SUCCESS,
	payload: {data},
});

export const getAnalysisTimeFailure = error => ({
	type: GET_ANALYSIS_TIME_FAILURE,
	payload: {error},
});
