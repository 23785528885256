import {alpha} from '@material-ui/core/styles';
import themeStyle from '../../theme';

const drawerWidth = 240;
const styles = theme => ({
	root: {
		display: 'flex',
		marginBottom: 58,
	},
	grow: {
		flexGrow: 1,
	},
	appBar: {
		height: 50,
		padding: `0 ${theme.spacing() * 2}px`,
		display: 'flex',
		justifyContent: 'center',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
	},
	MuiAvatar: {
		width: '30px',
		height: '30px',
	},
	headerTollBar: {
		minHeight: '50px',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 3,
		marginRight: 20,
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		bottom: 0,
		height: `calc(100% - 60px)`,
	},
	drawerOpen: {
		backgroundColor: themeStyle.color.themeSecundary,
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		backgroundColor: themeStyle.color.themeSecundary,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(8),
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	backButton: {
		color: theme.palette.common.white,
		padding: '5px',
		fontSize: '35px',
		cursor: 'pointer',
	},
	iconMenu: {
		color: theme.palette.common.white,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(9),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		paddingTop: theme.spacing(),
		paddingRight: theme.spacing(),
		paddingBottom: theme.spacing(),
		paddingLeft: theme.spacing(10),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
});

export default styles;
