/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Loading = styled.div`
	display: ${({loading}) => (loading === 'true' ? 'flex' : 'none')};
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	left: 0;
	top: 0;
	width: 100%;
	height: 120%;
	min-height: 120%;
`;
