/* eslint-disable no-nested-ternary */
import React, {memo} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {HasRoles} from 'common/ACL';

function Role({roles, ...props}) {
	const show = HasRoles(roles);

	return show ? (
		<Route {...props} />
	) : (
		window.location.pathname === props.path && <Redirect to="/unauthorized" />
	);
}

export default memo(Role);
