// eslint-disable-next-line import/no-cycle
import {
	GET_DAMAGES_BEGIN,
	GET_DAMAGES_SUCCESS,
	GET_DAMAGES_FAILURE,
	GET_LAST_TRIPS_BEGIN,
	GET_LAST_TRIPS_SUCCESS,
	GET_LAST_TRIPS_FAILURE,
	GET_SIMILARS_BEGIN,
	GET_SIMILARS_SUCCESS,
	GET_SIMILARS_FAILURE,
	SEND_IS_NOT_SIMILAR,
	PUT_CREATE_DAMAGE_SUCCESS,
	GET_REPORT_DATE,
} from './damagesAction';

const initialState = {
	data: [],
	isLoading: false,
	error: null,
	lastTrips: [],
	similars: [],
	damageCreated: {},
	reportDate: null,
};

export function damagesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_DAMAGES_BEGIN:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case GET_DAMAGES_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};

		case GET_DAMAGES_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
				data: [],
			};

		case GET_REPORT_DATE:
			return {
				...state,
				isLoading: false,
				reportDate: action.payload.data,
			};

		case PUT_CREATE_DAMAGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				damageCreated: action.payload.data,
			};

		default:
			return state;
	}
}

export function lastTripsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_LAST_TRIPS_BEGIN:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case GET_LAST_TRIPS_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};

		case GET_LAST_TRIPS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
				data: [],
			};

		default:
			return state;
	}
}

export function similarsDamage(state = initialState, action) {
	switch (action.type) {
		case GET_SIMILARS_BEGIN:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case GET_SIMILARS_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};

		case GET_SIMILARS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
				data: [],
			};

		case SEND_IS_NOT_SIMILAR:
			return {
				...state,
				isLoading: false,
				error: null,
				data: action.payload.data,
			};

		default:
			return state;
	}
}
