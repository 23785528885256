import React from 'react';
import {
	Notes,
} from '@material-ui/icons';

import Operations from '@material-ui/icons/Settings';
import Damages from '@material-ui/icons/Build';
import ReportIcon from '@material-ui/icons/Report';
import Money from '@material-ui/icons/AttachMoney';
import Marketing from '@material-ui/icons/ShowChart';
import NearMeIcon from '@material-ui/icons/NearMe';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import NavigationIcon from '@material-ui/icons/Navigation';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import Description from '@material-ui/icons/Description';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Room from '@material-ui/icons/Room';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import BusinessIcon from '@material-ui/icons/Business';
import Badge from '@material-ui/core/Badge';
import GavelIcon from '@material-ui/icons/Gavel';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash';
import CategoryIcon from '@material-ui/icons/Category';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import GitHubIcon from '@material-ui/icons/GitHub';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import StoreIcon from '@material-ui/icons/Store';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import {Roles} from 'common/ACL';

const GetMenuItems = (versions = 0) => {
	const json = {
		list: [
			{
				id: 0,
				items: [
					{
						id: 1,
						name: 'Usuários',
						icon: <AccountBoxIcon />,
						roles: [
							Roles.analystN1,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.fraud,
							Roles.street,
							Roles.office,
							Roles.financial,
							Roles.people,
							Roles.marketing,
							Roles.tech,
							Roles.iot,
							Roles.heads,
							Roles.sellers,
						],
						subitems: [
							{
								id: 1,
								name: 'Usuários',
								icon: <SupervisorAccountIcon />,
								path: '/operation/users',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.people,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
									Roles.sellers,
								],
							},
							{
								id: 2,
								name: 'Cartão de terceiros',
								icon: <CreditCardIcon />,
								path: '/operation/evaluate-third-party-card',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.fraud,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
									Roles.office,
								],
							},
							{
								id: 3,
								name: 'Débitos V2',
								icon: <MoneyOffIcon />,
								path: '/financial/debits',
								roles: [
									Roles.office,
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 4,
								name: 'Gestão ACL',
								icon: <Notes />,
								path: '/operation/manager-acl',
								roles: [
									Roles.internal_itsm_acl,
									Roles.internal_operations
								],
							},
						],
					},
					{
						id: 2,
						name: 'Reservas',
						icon: <NearMeIcon />,
						roles: [
							Roles.analystN1,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.fraud,
							Roles.street,
							Roles.office,
							Roles.financial,
							Roles.marketing,
							Roles.tech,
							Roles.iot,
							Roles.heads,
							Roles.sellers,
						],
						subitems: [
							{
								id: 0,
								name: 'Dados de Reservas',
								icon: <InsertChartIcon />,
								path: '/',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 1,
								name: 'Reservas',
								icon: <NavigationIcon />,
								path: '/reservations',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
									Roles.sellers,
								],
							},
							{
								id: 2,
								name: 'Análise de Risco',
								icon: <SecurityIcon />,
								path: '/risk-analysis',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
						],
					},
					{
						id: 3,
						name: 'Operação',
						icon: <Operations />,
						roles: [
							Roles.analystN1,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.marketing,
							Roles.fraud,
							Roles.street,
							Roles.office,
							Roles.financial,
							Roles.tech,
							Roles.iot,
							Roles.heads,
							Roles.wash,
						],
						subitems: [
							{
								id: 1,
								name: 'Reporte de Danos',
								icon: <ReportIcon />,
								path: '/operation/damages',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 0,
								name: 'Reporte de Danos (IA)',
								icon: <ReportIcon />,
								path: '/operation/report-damages',
								roles: [
									// Roles.analystN1,
									// Roles.analystN2,
									// Roles.quality,
									// Roles.supervisor,
									// Roles.supervisorMidnight,
									// Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 1,
								name: 'Novo Reporte de Danos V0',
								icon: <ReportIcon />,
								path: '/operation/sinister-damages?',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.marketing,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 6,
								name: 'Treinamento (IA)',
								icon: <PhotoLibraryIcon />,
								path: '/operation/training-ia',
								roles: [
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 2,
								name: 'Sinistros',
								icon: <Damages />,
								path: '/operation/sinister-management',
								roles: [
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 3,
								name: 'Lavagens ',
								icon: <LocalCarWashIcon />,
								path: '/operation/washes',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
									Roles.wash,
								],
							},
							{
								id: 4,
								name: 'Lavadores',
								icon: <LocalDrinkIcon />,
								path: '/operation/washers',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 5,
								name: 'Prestador serv.',
								icon: <BusinessIcon />,
								path: '/operation/services-providers',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 6,
								name: 'PODs',
								icon: <Room />,
								path: '/operation/pods',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 7,
								name: 'Multas',
								icon: <GavelIcon />,
								path: '/operation/fines',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 8,
								name: 'Movimentações',
								icon: <FlipToBackIcon />,
								path: '/operation/movements',
								roles: [
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 9,
								name: 'Revisões',
								icon: <BusinessCenterIcon />,
								path: '/operation/revisions',
								roles: [
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
						],
					},
					{
						id: 4,
						name: 'Reembolsos',
						icon: <AccountBalanceIcon />,
						roles: [
							Roles.analystN1,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.fraud,
							Roles.financial,
							Roles.tech,
							Roles.iot,
							Roles.heads,
						],
						subitems: [
							{
								id: 0,
								name: 'Combustível',
								icon: <LocalGasStationIcon />,
								path: '/operation/refund/refuels',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
						],
					},
					{
						id: 6,
						name: 'Veículos',
						icon: <DriveEtaIcon />,
						roles: [
							Roles.analystN1,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.fraud,
							Roles.street,
							Roles.office,
							Roles.financial,
							Roles.marketing,
							Roles.tech,
							Roles.iot,
							Roles.heads,
							Roles.wash,
							Roles.sellers,
						],
						subitems: [
							{
								id: 1,
								name: 'Locadoras',
								icon: <EmojiTransportationIcon />,
								path: '/operation/rents',
								roles: [
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.office,
									Roles.financial,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 2,
								name: 'Modelos',
								icon: <CategoryIcon />,
								path: '/operation/models',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 3,
								name: 'Veículos',
								icon: <DriveEtaIcon />,
								path: '/operation/vehicles',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
									Roles.wash,
									Roles.sellers,
								],
							},
							{
								id: 3,
								name: 'Revendas',
								icon: <StoreIcon />,
								path: '/operation/resellers',
								roles: [
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
						],
					},
					{
						id: 7,
						name: 'Risco',
						icon: <SecurityIcon />,
						roles: [
							Roles.fraud,
							Roles.tech,
							Roles.iot,
							Roles.heads,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisor_night,
						],
						subitems: [
							{
								id: 1,
								name: 'T-Safe Hibrid Guard',
								icon: <PersonPinCircleIcon />,
								path: '/risk/t-safe-hibrid-guard',
								roles: [
									Roles.fraud,
									Roles.supervisorMidnight,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 2,
								name: 'Monitoramento',
								icon: <WhatshotIcon />,
								path: '/risk/monitoring?hasBooking=1&events=1',
								roles: [
									Roles.fraud,
									Roles.supervisorMidnight,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
						],
					},
					{
						id: 8,
						name: 'Marketing',
						icon: <Marketing />,
						roles: [
							Roles.analystN1,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.fraud,
							Roles.financial,
							Roles.marketing,
							Roles.tech,
							Roles.iot,
							Roles.heads,
						],
						subitems: [
							{
								id: 1,
								name: 'Estratégia de preços',
								icon: <Money />,
								path: '/marketing/strategy',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
							{
								id: 2,
								name: 'Vouchers',
								icon: <CardGiftcardIcon />,
								path: '/marketing/vouchers',
								roles: [
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
								],
							},
						],
					},

					{
						id: 9,
						name: 'Financeiro',
						icon: <MonetizationOn />,
						roles: [Roles.financial, Roles.tech, Roles.iot, Roles.heads],
						subitems: [
							{
								id: 1,
								name: 'ConectCar',
								icon: <Description />,
								path: '/financial/conectcar',
								roles: [Roles.financial, Roles.tech, Roles.iot, Roles.heads],
							},
							{
								id: 2,
								name: 'Suppliers',
								icon: <Description />,
								path: '/financial/Suppliers',
								roles: [Roles.financial, Roles.tech, Roles.iot, Roles.heads],
							},
						],
					},
					{
						id: 11,
						name: 'Relatórios',
						icon: <Description />,
						to: '/reports',
						roles: [
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.fraud,
							Roles.office,
							Roles.financial,
							Roles.marketing,
							Roles.tech,
							Roles.iot,
							Roles.heads,
						],
					},
					{
						id: 12,
						name: 'Versões',
						to: '/versions',
						icon: (
							<Badge
								badgeContent={versions}
								color="error"
								overlap="rectangular"
							>
								<GitHubIcon />
							</Badge>
						),
						roles: [
							Roles.analystN1,
							Roles.analystN2,
							Roles.quality,
							Roles.supervisor,
							Roles.supervisorMidnight,
							Roles.fraud,
							Roles.street,
							Roles.office,
							Roles.financial,
							Roles.people,
							Roles.marketing,
							Roles.tech,
							Roles.iot,
							Roles.heads,
						],
					},
				],
			},
		],
	};
	return json;
};

export default GetMenuItems;
