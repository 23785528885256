/* eslint-disable react/prop-types */
import React, {useEffect, Suspense, lazy, useMemo} from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import {LinearProgress} from '@material-ui/core';
import {Roles, HasRoles} from 'common/ACL';
import Unauthorized from 'common/Unauthorized';
import Dashboard from 'common/Dashboard';
import {useAuth} from 'services/ProviderAuth/ProviderAuth';
import {Alerts} from 'services/Alerts';
import Login from 'pages/Login';
import PrivateRoute from './PrivateRoutes';

const SummaryReservations = lazy(() =>
	import('pages/Reservations/templates/SummaryReservations'),
);

const Screen404 = lazy(() => import('pages/404'));
const DamagesReportPage = lazy(() => import('pages/Operations/DamagesReport'));
const ReportDamanges = lazy(() => import('pages/Operations/ReportDamanges'));
const UsersPage = lazy(() => import('pages/Users'));
const User = lazy(() => import('pages/Users/User'));
const ManagerACL = lazy(() => import('pages/Users/ManagerACL'));
const SinisterDamages = lazy(() => import('pages/Operations/SinisterDamages'));
const ViewSinisterDamages = lazy(() =>
	import('pages/Operations/SinisterDamages/ViewDamage'),
);
const ViewDamageReport = lazy(() =>
	import('pages/Operations/SinisterDamages/ViewReports'),
);
const Reservations = lazy(() => import('pages/Reservations'));
const NewDebits = lazy(() => import('pages/Financial/Debits'));
const DebitsBooking = lazy(() =>
	import('pages/Financial/Debits/BookingDebits'),
);
const DebitsUser = lazy(() => import('pages/Financial/Debits/UserDebits'));

const ReservationDetails = lazy(() =>
	import('pages/Reservations/templates/Details'),
);

const RiskAnalysis = lazy(() => import('pages/ManualRiskAnalysis'));
const RiskAnalysisDetails = lazy(() =>
	import('pages/ManualRiskAnalysis/components/Details'),
);
const ConectCar = lazy(() => import('pages/ConectCar'));
const Suppliers = lazy(() => import('pages/Suppliers'));
const AllReports = lazy(() => import('pages/AllReports'));
const PODs = lazy(() => import('pages/Operations/PODs'));
// const Erp = lazy(() => import('pages/Erp'));
const TSafeHibridGuard = lazy(() => import('pages/Risk/Hibrid'));
const Guard = lazy(() => import('pages/Risk/Guard'));
const ThirdParties = lazy(() => import('pages/Risk/ThirdParties/Hibrid'));
// const Shopping = lazy(() => import('pages/Financial/Shopping'));

const NewWashes = lazy(() => import('pages/Operations/Wash/NewFlux'));
const SinisterManagement = lazy(() =>
	import('pages/Operations/SinisterManagement'),
);
const SinisterManagementKanban = lazy(() =>
	import('pages/Operations/SinisterManagement/schemas/Board'),
);
const TrainingIA = lazy(() => import('pages/Operations/TrainingIA'));

const Movements = lazy(() => import('pages/Operations/Movements'));
const MovementDetails = lazy(() =>
	import('pages/Operations/Movements/src/components/DetailsGroup'),
);
const Resellers = lazy(() => import('pages/Operations/Resellers'));
const LotVehiclesForSale = lazy(() =>
	import('pages/Operations/Resellers/src/pages/DetailsLotForSale'),
);
const LotVehicleForSale = lazy(() =>
	import('pages/Operations/Resellers/src/pages/DetailsVehicleForSale'),
);
const Revisions = lazy(() => import('pages/Operations/Revisions'));
// eslint-disable-next-line no-unused-vars
const RevisionDetails = lazy(() =>
	import('pages/Operations/Revisions/components/RevisionDetails'),
);
const Strategy = lazy(() => import('pages/Marketing/Strategy'));
const Vouchers = lazy(() => import('pages/Marketing/Vouchers'));
const Fines = lazy(() => import('pages/Fines'));
const Fine = lazy(() => import('pages/Fines/Details'));
const EvaluateThirdPartyCard = lazy(() =>
	import('pages/EvaluateThirdPartyCard'),
);
const Inactivity = lazy(() => import('services/Inactivity'));
const Wrapper = lazy(() => import('common/Layout'));
const Rents = lazy(() => import('pages/Vehicles/Rents'));
const ModelsVehicle = lazy(() => import('pages/Vehicles/ModelsVehicle'));
const ModelVehicleDetails = lazy(() =>
	import('pages/Vehicles/ModelsVehicle/Components/ModelVehicleDetails'),
);
const Lots = lazy(() => import('pages/Vehicles/Lots'));
const Vehicles = lazy(() => import('pages/Vehicles/Vehicles'));
const VehicleDetails = lazy(() =>
	import('pages/Vehicles/Vehicles/components/ViewDetailsVehicle'),
);
const Washer = lazy(() => import('pages/Operations/Washer'));
const ServicesProviders = lazy(() =>
	import('pages/Operations/ServicesProviders'),
);
const ServicesProvidersFormEditon = lazy(() =>
	import('pages/Operations/ServicesProviders/FormEditon'),
);
const Refuels = lazy(() => import('pages/Refund/Refuel'));
// const Vaccinations = lazy(() => import('pages/Refund/Vaccination'));
const Versions = lazy(() => import('pages/Versions'));
const PathAnalyser = lazy(() => import('pages/PathAnalyser'));

function Routes() {
	const {loadingGetUser, user} = useAuth();

	if (window.location.href.indexOf('jarvix.web.app') >= 0) {
		window.location.href = window.location.href.replace(
			'jarvix.web.app',
			'friday.turbi.app',
		);
	}

	function printXSS() {
		setTimeout(
			console.log.bind(
				console,
				'%c%s',
				'color: red; background: yellow; font-size: 24px;',
				'AVISO',
			),
		);
		setTimeout(
			console.log.bind(
				console,
				'%c%s',
				'font-size: 18px;',
				'\u00c9 poss\u00edvel que o uso deste console permita que invasores falsifiquem sua identidade para roubar informa\u00e7\u00f5es por meio de um ataque chamado "Self-XSS".\nN\u00e3o insira nem cole c\u00f3digos que voc\u00ea n\u00e3o conhece.',
			),
		);
	}

	useEffect(() => {
		printXSS();
	}, []);

	const GetRoutes = useMemo(() => {
		return (
			<Router>
				<Wrapper loadingProfiler={!user && loadingGetUser}>
					<Switch>
						<Route exact path="/login">
							{user ? <Redirect to="/" /> : <Login />}
						</Route>
						<Route path="/unauthorized" component={Unauthorized} />
						<PrivateRoute
							exact
							path="/"
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.people,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							component={
								HasRoles([
									Roles.analystN1,
									Roles.analystN2,
									Roles.quality,
									Roles.supervisor,
									Roles.supervisorMidnight,
									Roles.fraud,
									Roles.street,
									Roles.office,
									Roles.financial,
									Roles.marketing,
									Roles.tech,
									Roles.iot,
									Roles.heads,
									Roles.people,
								]) ? (
									<SummaryReservations />
								) : (
									<Dashboard />
								)
							}
						/>
						{/* Operation */}
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/refuels"
							component={<Refuels />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.people,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.sellers,
							]}
							path="/operation/users"
							component={<UsersPage />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/refund/refuels"
							component={<Refuels />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/report-damages"
							component={<ReportDamanges />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/damages"
							component={<DamagesReportPage />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.people,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.sellers,
							]}
							path="/operation/user/:id"
							exact
							component={<User />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.people,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.sellers,
							]}
							path="/operation/manager-acl"
							exact
							component={<ManagerACL />}
						/>

						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/pods"
							component={<PODs />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.wash,
							]}
							path="/operation/washes"
							component={<NewWashes />}
						/>
						<PrivateRoute
							roles={[
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/sinister-management/"
							component={<SinisterManagement />}
						/>
						<PrivateRoute
							roles={[
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							exact
							path="/operation/sinister-management-kanban"
							component={<SinisterManagementKanban />}
						/>
						<PrivateRoute
							roles={[
								Roles.street,
								Roles.office,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/movements"
							component={<Movements />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/resellers"
							component={<Resellers />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/reseller/lotVehiclesForSale/:id"
							component={<LotVehiclesForSale />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/reseller/lotVehicleForSale/:id"
							component={<LotVehicleForSale />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/training-ia"
							component={<TrainingIA />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/movement/:id"
							component={<MovementDetails />}
						/>
						<PrivateRoute
							roles={[
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/revisions"
							component={<Revisions />}
						/>
						{/* <PrivateRoute
							roles={['admin', 'dev', 'advanced', 'user']}
							path="/operation/revisions/:id"
							component={<RevisionDetails />}
						/> */}
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/evaluate-third-party-card"
							component={<EvaluateThirdPartyCard />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							exact
							path="/operation/fines"
							component={<Fines />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							exact
							path="/operation/fine/:id"
							component={<Fine />}
						/>
						<PrivateRoute
							roles={[
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/rents"
							component={<Rents />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/models"
							component={<ModelsVehicle />}
						/>
						<PrivateRoute
							roles={[
								// Roles.analystN1,
								// Roles.analystN2,
								// Roles.quality,
								// Roles.supervisor,
								// Roles.fraud,
								Roles.street,
								Roles.office,
								// Roles.financial,
								// Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/model/:id"
							component={<ModelVehicleDetails />}
						/>
						<PrivateRoute
							roles={[
								/* Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.people,
								Roles.marketing, */
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/lots"
							component={<Lots />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.wash,
								Roles.sellers,
							]}
							path="/operation/vehicles"
							component={<Vehicles />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.wash,
								Roles.sellers,
							]}
							path="/operation/vehicle/:plate"
							component={<VehicleDetails />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/washers"
							component={<Washer />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/services-providers"
							component={<ServicesProviders />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.marketing,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.street,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/sinister-damages"
							component={<SinisterDamages />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.street,
							]}
							path="/operation/damage/:damageId"
							component={<ViewSinisterDamages />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.street,
							]}
							path="/operation/reports/vehicle/:vehicleId"
							component={<ViewDamageReport />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/operation/services-provider/:id"
							component={<ServicesProvidersFormEditon />}
						/>
						{/* Risks */}
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.supervisorMidnight,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/risk/t-safe-hibrid-guard"
							component={<TSafeHibridGuard />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.supervisorMidnight,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/risk/t-secure"
							component={<Guard />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.supervisorMidnight,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/risk/monitoring"
							component={<ThirdParties />}
						/>
						{/* Marketing */}
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/marketing/strategy"
							component={<Strategy />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/marketing/vouchers"
							component={<Vouchers />}
						/>
						{/* Reservations */}
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.sellers,
							]}
							exact
							path="/reservations"
							component={<Reservations />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
								Roles.sellers,
							]}
							exact
							path="/reservation/:id/:type"
							component={<ReservationDetails />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							exact
							path="/risk-analysis"
							component={<RiskAnalysis />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.office,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							exact
							path="/risk-analysis/:id"
							component={<RiskAnalysisDetails />}
						/>
						{/* Financial */}
						{/* <PrivateRoute
							roles={['admin', 'dev', 'advanced', 'financial']}
							path="/financial/shopping"
							component={<Shopping />}
						/> */}

						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/financial/conectcar"
							component={<ConectCar />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.financial,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/financial/suppliers"
							component={<Suppliers />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/financial/debits"
							component={<NewDebits />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/financial/debit/booking/:bookingId"
							component={<DebitsBooking />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/financial/debit/user/:userId"
							component={<DebitsUser />}
						/>
						{/* General */}
						{/* <PrivateRoute
							roles={['admin', 'dev', 'financial']}
							path="/erp"
							component={<Erp />}
						/> */}
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.people,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/reports"
							component={<AllReports />}
						/>
						<PrivateRoute
							roles={[
								Roles.analystN1,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisorMidnight,
								Roles.fraud,
								Roles.street,
								Roles.office,
								Roles.financial,
								Roles.people,
								Roles.marketing,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/versions"
							component={<Versions />}
						/>
						<PrivateRoute
							roles={[
								Roles.fraud,
								Roles.analystN2,
								Roles.quality,
								Roles.supervisor,
								Roles.supervisor_night,
								Roles.tech,
								Roles.iot,
								Roles.heads,
							]}
							path="/risk/pathAnalyser"
							component={<PathAnalyser />}
						/>
						<Route path="*">
							<Screen404 />
						</Route>
					</Switch>
				</Wrapper>
			</Router>
		);
	}, [user, loadingGetUser]);

	return (
		<Suspense fallback={<LinearProgress color="secondary" />}>
			<Inactivity />
			<Alerts />
			{GetRoutes}
		</Suspense>
	);
}

export default Routes;
