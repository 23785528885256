import React, {memo} from 'react';
import {Typography} from '@material-ui/core';
import GridContainer from 'common/Grid';

function Unauthorized() {
	return (
		<GridContainer>
			<Typography variant="h6">
				Ooops, desculpa mas você não tem permissão para acessar esse conteúdo
			</Typography>
		</GridContainer>
	);
}

export default memo(Unauthorized);
