import {
	GET_CLEANERS_SUCCESS,
	CREATE_TRIP_CLEANER,
	GET_COMPANIES_SUCCESS,
	GET_WASHERS_SUCCESS,
	GET_VEHICLES_SUCCESS,
} from './Cleaner.actions';

const initialState = {
	data: [],
	washers: [],
	companies: [],
	vehicles: [],
};

const CleanerReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_TRIP_CLEANER:
			return {
				...state,
				data: action.payload.data,
			};
		case GET_CLEANERS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
			};
		case GET_WASHERS_SUCCESS:
			return {
				...state,
				washers: action.payload.data,
			};
		case GET_COMPANIES_SUCCESS:
			return {
				...state,
				companies: action.payload.data,
			};
		case GET_VEHICLES_SUCCESS:
			return {
				...state,
				vehicles: action.payload.data,
			};
		default:
			return state;
	}
};

export default CleanerReducer;
