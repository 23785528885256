/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import store from 'store';
import {UPDATE_ALERTS} from './typing';

const updateAlerts = value => ({
	type: UPDATE_ALERTS,
	value,
});

export const addAlert = (msg, title, type, options, id) => dispatch => {
	try {
		const alert = {id, msg, title, type, options};
		const state = [...store.getState().alerts, alert];
		dispatch(updateAlerts(_.uniqBy(state, alertItem => alertItem.id)));
	} catch (error) {
		console.log(error);
	}
};

export const removeAlert = id => dispatch => {
	const state = [...store.getState().alerts].filter(item => item.id !== id);
	dispatch(updateAlerts(_.uniqBy(state, alertItem => alertItem.id)));
};
