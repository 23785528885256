import React, {memo, useState} from 'react';
import {connect} from 'react-redux';
import store from 'store';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import StackTrace from 'stacktrace-js';
import {Alert, AlertTitle} from '@material-ui/lab';
import {Grid, Button, Typography} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {FirebaseDefault} from 'utils/firebase';
import DD from 'lib/datadog';
import {addAlert, removeAlert} from './actions';

let lastReport;
const LIMIT_ALERTS_TO_RENDER = 6;

class AlertClass {
	async report(error, options) {
		try {
			if (
				process.env.NODE_ENV !== 'development' &&
				!options?.notification &&
				!window.inactive
			) {
				const appVersion = process.env.REACT_APP_VERSION_APPLICATION;
				const trace = await StackTrace.fromError(error);
				const {uid, displayName, email} =
					FirebaseDefault.auth().currentUser || {};
				let msg =
					error.response?.data?.message ||
					error.response?.data?.Error ||
					error.response?.data?.error ||
					error.response?.data?.err ||
					error.data?.message ||
					error.data?.Error ||
					error.data?.error ||
					error.data?.err ||
					error.message ||
					error.Error ||
					error.error ||
					error.err;
				if (typeof msg === 'object') {
					msg = JSON.stringify(msg);
				}
				const created = moment();
				if (
					!(
						lastReport?.msg === msg &&
						lastReport?.url === window.location.href &&
						created.diff(moment(), 'seconds') < 30
					)
				) {
					lastReport = {
						msg,
						url: window.location.href,
						created: moment(created),
					};
					let text;
					text = "*Oops! It's a Friday error* \n";
					text += `${moment(created).format('DD/MMMM - HH:mm:ss')}: ${msg}`;
					text += ` ${window.location.href}`;
					if (error.name) {
						text += '\n\n';
						text += `\`${error.name}\``;
					}
					text += '\n\n';
					text += `\`Versão: ${appVersion}\``;
					text += '\n\n';
					text += `\`\`\`${JSON.stringify({
						uid,
						displayName,
						email,
					})}\`\`\``;
					text += '\n\n';
					if (options?.additionalInformation) {
						text += `\`\`\`${JSON.stringify(
							options.additionalInformation,
						)}\`\`\``;
						text += '\n\n';
					}
					text += trace
						.map(
							item =>
								`_function ${item.functionName}_ :: ${item.lineNumber}\n${item.fileName}`,
						)
						.join('\n-----\n');
					DD.datadogRum.addError(new Error(msg), {context: text});
				}
			}
		} catch (err) {
			DD.datadogRum.addError(err);
		}
	}

	increment(msg, title, type, options, id) {
		try {
			const {dispatch} = store;
			const ID = id || uuidv4();
			const message =
				typeof msg === 'string'
					? msg
					: msg?.response?.data?.message ||
					  msg?.response?.data?.friendlyMessage ||
					  msg?.response?.data?.Error ||
					  msg?.response?.data?.error ||
					  msg?.response?.data?.err ||
					  msg?.data?.message ||
					  msg?.data?.friendlyMessage ||
					  msg?.data?.Error ||
					  msg?.data?.error ||
					  msg?.data?.err ||
					  msg?.message ||
					  msg?.Error ||
					  msg?.error ||
					  msg?.err ||
					  JSON.stringify(msg);
			if (typeof message === 'object')
				dispatch(addAlert(JSON.stringify(message), title, type, options, ID));
			else dispatch(addAlert(message, title, type, options, ID));
			let time;
			if (!options || typeof options.timeOut !== 'number') {
				time = 5000;
			}
			if (options && options.timeOut > 0) {
				time = options.timeOut;
			}
			if (time) {
				setTimeout(() => {
					this.remove(ID);
				}, time);
			}
		} catch (error) {
			console.log(error);
			DD.datadogRum.addError(error);
		}
	}

	async error(msg, title, options, id) {
		this.increment(msg, title, 'error', options, id);
	}

	async errorReport(msg, title, options, id) {
		this.increment(msg, title, 'error', options, id);
		this.report(msg, options);
	}

	warning(msg, title, options, id) {
		this.increment(msg, title, 'warning', options, id);
	}

	success(msg, title, options, id) {
		this.increment(msg, title, 'success', options, id);
	}

	info(msg, title, options, id) {
		this.increment(msg, title, 'info', options, id);
	}

	remove(id) {
		const {dispatch} = store;
		dispatch(removeAlert(id));
	}

	clear(alerts = store.getState().alerts) {
		alerts.forEach(item => {
			if (item.options && item.options.onCloseClick) {
				item.options.onCloseClick();
			}
			this.remove(item.id);
		});
	}
}

const toastr = new AlertClass();

export const Alerts = memo(
	connect(state => ({
		alerts: state.alerts,
	}))(({alerts}) => {
		const [show, setShow] = useState(true);
		const [showMore, setShowMore] = useState(false);
		const notifications = alerts.filter(
			(item, index) =>
				index <= LIMIT_ALERTS_TO_RENDER &&
				item.options &&
				item.options.notification,
		);
		const snacks = alerts.filter(
			(item, index) =>
				index <= LIMIT_ALERTS_TO_RENDER &&
				(!item.options || !item.options.notification),
		);
		const renderAlert = item => (
			<Alert
				variant="filled"
				severity={item.type}
				onClose={
					item.options &&
					item.options.onCloseClick &&
					(e => {
						e.stopPropagation();
						if (item.options && item.options.onCloseClick) {
							item.options.onCloseClick();
						}
						toastr.remove(item.id);
					})
				}
				style={{
					width: '100%',
					fontSize: '0.8em',
				}}
			>
				{item.title && (
					<AlertTitle style={{fontSize: '1.2em'}}>{item.title}</AlertTitle>
				)}
				{item.msg}
			</Alert>
		);
		return (
			<>
				{show && notifications.length > 0 && (
					<Grid
						container
						spacing={1}
						style={{
							position: 'fixed',
							zIndex: 9999,
							top: 62,
							right: 8,
							flexDirection: 'column',
							width: 405,
							overflowY: 'auto',
						}}
					>
						{notifications.length > 3 &&
							(!showMore ? (
								<Grid item>
									<Button
										variant="contained"
										style={{
											float: 'left',
											boxShadow: 'none',
											padding: '2px 8px',
										}}
										onClick={() => {
											setShow(false);
											toastr.clear(notifications);
										}}
										startIcon={<Close style={{width: 14}} />}
									>
										<Typography variant="caption">Limpar</Typography>
									</Button>
									<Button
										variant="contained"
										style={{
											float: 'right',
											boxShadow: 'none',
											padding: '2px 8px',
										}}
										onClick={() => setShowMore(true)}
									>
										<Typography variant="caption">
											Mostrar todas as{' '}
											{notifications.length <= LIMIT_ALERTS_TO_RENDER
												? notifications.length
												: LIMIT_ALERTS_TO_RENDER}
										</Typography>
									</Button>
								</Grid>
							) : (
								<Grid item>
									<Button
										variant="contained"
										style={{
											float: 'left',
											boxShadow: 'none',
											padding: '2px 8px',
										}}
										onClick={() => {
											setShow(false);
											toastr.clear(notifications);
										}}
										startIcon={<Close style={{width: 14}} />}
									>
										<Typography variant="caption">Limpar</Typography>
									</Button>
									<Button
										variant="contained"
										style={{
											float: 'right',
											boxShadow: 'none',
											padding: '2px 8px',
										}}
										onClick={() => setShowMore(false)}
									>
										<Typography variant="caption">Mostrar menos</Typography>
									</Button>
								</Grid>
							))}
						{notifications.map(
							(item, i) =>
								((showMore && i <= LIMIT_ALERTS_TO_RENDER) ||
									(!showMore && i < 2)) && (
									<Grid key={item.id} item>
										{item.options && item.options.onclick ? (
											<Button
												component="a"
												style={{padding: 0, width: '100%', textAlign: 'left'}}
												onClick={item.options.onclick}
											>
												{renderAlert(item)}
											</Button>
										) : (
											renderAlert(item)
										)}
									</Grid>
								),
						)}
					</Grid>
				)}
				{show && snacks.length > 0 && (
					<Grid
						container
						spacing={1}
						style={{
							position: 'fixed',
							zIndex: 9999,
							bottom: 8,
							left: '50%',
							transform: 'translateX(-50%)',
							flexDirection: 'column-reverse',
							alignItems: 'center',
							overflowY: 'auto',
						}}
					>
						{snacks.map(
							(item, index) =>
								index < LIMIT_ALERTS_TO_RENDER && (
									<Grid key={item.id} item style={{width: 'auto'}}>
										{renderAlert(item)}
									</Grid>
								),
						)}
					</Grid>
				)}
			</>
		);
	}),
);

export default toastr;
