/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import SEO from 'react-seo-component';
import {ThemeProvider} from '@material-ui/core';
import {Router} from 'react-router';
import history from './services/Routes/history';
import Routes from './services/Routes';
import ProvideAuth from './services/ProviderAuth/ProviderAuth';
import store from './store';
import Loading from './common/Loading';
import {CustomMaterialTheme} from './theme';

require('react-dropzone-uploader/dist/styles.css');
require('utils/prototypes');
require('utils/listenerSintaxError');
require('./CssFont.css');

export class App {
	constructor() {
		this.render();
	}

	render() {
		ReactDOM.render(
			<Provider store={store}>
				<ThemeProvider theme={CustomMaterialTheme()}>
					<SEO
						titleTemplate={process.env.REACT_APP_SUBTITLE}
						title={process.env.REACT_APP_TITLE}
						description="Painel backoffice para atendimento ao cliente Turbi."
						pathname="/"
						image="/favicon.png"
					/>
					<ProvideAuth>
						<Router history={history}>
							<Loading />
							<Routes />
						</Router>
					</ProvideAuth>
				</ThemeProvider>
			</Provider>,
			document.getElementById('root'),
		);
	}
}

export default new App();
