/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import toastr from 'services/Alerts';

import {requestWithRefreshToken} from 'utils/api';
import {onLoading, offLoading} from 'common/Loading/LoadingActions';

const baseUrlProd = 'https://us-central1-turbi-22388.cloudfunctions.net';

export const CREATE_TRIP_CLEANER = 'CREATE_TRIP_CLEANER';
export const GET_WASHERS_SUCCESS = 'GET_WASHERS_SUCCESS';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_RENTS_SUCCESS = 'GET_RENTS_SUCCESS';
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';

export const get = payload => {
	return async function action(dispatch) {
		try {
			const {data} = await requestWithRefreshToken(
				baseUrlProd,
				`/rentals/v1/companies${
					payload?.filter?.search ? `?search=${payload?.filter?.search}` : ``
				}`,
			);
			dispatch(getRentsSuccess({data}));
		} catch (error) {
			dispatch(getRentsSuccess({data: {content: []}}));
			toastr.warning(error?.response?.data?.message || error);
		}
	};
};

export const exclude = payLoad => {
	return async function action(dispatch) {
		try {
			dispatch(onLoading());
			await requestWithRefreshToken(
				baseUrlProd,
				`/rentals/v1/companies/${payLoad.id}`,
				'delete',
			);
			dispatch(get());
			toastr.success('Registro excluído com sucesso');
		} catch (error) {
			toastr.error(error?.response?.data?.message || error);
		} finally {
			dispatch(offLoading());
		}
	};
};

function removeNullObject(object) {
	const newObject = {};
	Object.keys(object).forEach(model => {
		if (typeof object[model] !== 'undefined' && object[model] !== null) {
			newObject[model] = object[model];
		}
	});
	return newObject;
}

export const update = async payLoad => {
	try {
		const {id} = payLoad;
		delete payLoad.id;
		delete payLoad.contacts;
		delete payLoad.updated;
		delete payLoad.added;
		delete payLoad.excluded;
		delete payLoad.author;
		const removeNull = removeNullObject(payLoad);
		await requestWithRefreshToken(
			baseUrlProd,
			`/rentals/v1/companies/${id}`,
			'put',
			false,
			removeNull,
		);
		toastr.success('Registro atualizado com sucesso');
	} catch (error) {
		toastr.error(error?.response?.data?.message || error);
		const {failure} = (error.response || {}).data || {};
		(failure || []).map(fail => toastr.error(fail.message));
	}
};

export const updateContact = async payLoad => {
	try {
		const {id, rentalId} = payLoad;
		delete payLoad.id;
		delete payLoad.rentalId;
		delete payLoad.updated;
		delete payLoad.added;

		await requestWithRefreshToken(
			baseUrlProd,
			`/rentals/v1/companies/${rentalId}/contact/${id}`,
			'put',
			false,
			payLoad,
		);
		toastr.success('Registro atualizado com sucesso');
	} catch (error) {
		toastr.error(error?.response?.data?.message || error);
	}
};

export const create = async payLoad => {
	try {
		const {data} = await requestWithRefreshToken(
			baseUrlProd,
			'/rentals/v1/companies',
			'post',
			false,
			payLoad,
		);
		toastr.success('Locadora cadastrada com sucesso !');
		return data;
	} catch (error) {
		toastr.error(error?.response?.data?.message || error);
		throw ((error || {}).response || {}).data;
	}
};

export const createTripCleaner = data => ({
	type: CREATE_TRIP_CLEANER,
	payload: data,
});

export const getRentsSuccess = data => ({
	type: GET_RENTS_SUCCESS,
	payload: data,
});

export const getVehiclesForWashSuccess = data => ({
	type: GET_VEHICLES_SUCCESS,
	payload: data,
});

export const getWashersSuccess = data => ({
	type: GET_WASHERS_SUCCESS,
	payload: data,
});

export const getCompaniesSuccess = data => ({
	type: GET_COMPANIES_SUCCESS,
	payload: data,
});
