export const ON_LOADING = 'on_loading';
export const OFF_LOADING = 'off_loading';

export const onLoading = () => ({
	type: ON_LOADING,
	payload: true,
});

export const offLoading = () => ({
	type: OFF_LOADING,
	payload: false,
});
