export const UPDATE_VERSIONS = 'UPDATE_VERSIONS';
export const UPDATE_NEW_VERSIONS = 'UPDATE_NEW_VERSIONS';

export const updateVersions = data => ({
	type: UPDATE_VERSIONS,
	payload: data,
});

export const updateNewVersions = data => ({
	type: UPDATE_NEW_VERSIONS,
	payload: data,
});
