import DD from 'lib/datadog';
import StackTrace from 'stacktrace-js';
import {FirebaseDefault} from './firebase';

const tryErrors = ['Loading chunk', "Unexpected token '<'"];

window.onerror = async (message, source, line, colno, error) => {
	const isSomeErroknown = tryErrors.filter(err => message.includes(err));
	if (process.env.NODE_ENV == 'development' && !window.inactive) {
		if (isSomeErroknown.length) {
			window.location.reload(true);
		}
		const appVersion = process.env.REACT_APP_VERSION_APPLICATION;
		let text;
		text = '<users/all> *CRITICAL SYNTAX ERROR* \n';
		text += ` ${window.location.href}`;
		const trace = error && (await StackTrace.fromError(error));
		if (FirebaseDefault.auth().currentUser) {
			const {uid, displayName, email} = FirebaseDefault.auth().currentUser;
			if (error?.name) {
				text += '\n\n';
				text += `\`${error?.name}\``;
			}
			text += '\n\n';
			text += `\`Versão: ${appVersion}\``;
			text += '\n\n';
			text += `\`\`\`${JSON.stringify({
				uid,
				displayName,
				email,
			})}\`\`\``;
		}
		text += '\n\n';
		text += `\`\`\`${message}\`\`\``;
		text += '\n';
		text += trace
			? trace
					.map(
						item =>
							`_function ${item.functionName}_ :: ${item.lineNumber}\n${item.fileName}`,
					)
					.join('\n-----\n')
			: '';

		DD.datadogRum.addError(error, {context: text});
	}
};
