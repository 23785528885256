/* eslint-disable import/no-cycle */
import firebaseModule from 'firebase';
import Config from 'utils/config';

let firebaseDefault;
let firebaseWashes;

export function initializeFireWashes() {
	firebaseWashes = firebaseModule.apps.find(app => app.name_ === 'Washes');
	if (!firebaseWashes) {
		firebaseWashes = firebaseModule.initializeApp(Config.washesApp, 'Washes');
	}
	return firebaseWashes;
}

export function initializeFireDefault() {
	firebaseDefault = firebaseModule.apps.find(app => app.name_ === '[DEFAULT]');
	if (!firebaseDefault) {
		firebaseDefault = firebaseModule.initializeApp(Config.firebase);
	}
	return firebaseDefault;
}

export const FirebaseDefault = initializeFireDefault();

export const FirebaseWashes = initializeFireWashes();

export const storage = () => {
	try {
		return FirebaseDefault.storage();
	} catch (error) {
		console.log(error);
	}
};

export default firebaseModule;
