import moment from 'moment';
import toastr from 'services/Alerts';
import { FirebaseDefault } from 'utils/firebase';
import store from 'store';
import { updateVersions, updateNewVersions } from './store/actions';

const db = FirebaseDefault.database();
export const versionsDb = db.ref('friday/versions');

let listener;
let listenerNews;

export const onListener = call => {
	listener = versionsDb.on('value', snap => {
		const versionSnap = snap.val() || {};
		const versions = Object.keys(versionSnap)
			.map(key => ({
				key,
				...versionSnap[key],
			}))
			.sort((a, b) => {
				return moment(a.dateRelease).diff(moment(b.dateRelease)) < 0 ? 1 : -1;
			});
		setTimeout(() => {
			store.dispatch(
				updateVersions({
					versions: Object.keys(versionSnap).map(key => ({
						key,
					})),
				}),
			);
		}, 1000 * 5);
		call(versions);
	});
};

export const offListener = () => {
	versionsDb.off('value', listener);
};

export const onNewsVersionListener = () => {
	listenerNews = versionsDb.on('value', snap => {
		const versionSnap = snap.val() || {};
		store.dispatch(
			updateNewVersions({
				versions: Object.keys(versionSnap).map(key => ({
					key,
				})),
			}),
		);
	});
};

export const offNewsVersionListener = () => {
	versionsDb.off('value', listenerNews);
};

export const CreateVersions = async payload => {
	try {
		await versionsDb.push().set(payload, error => {
			if (error) {
				toastr.error(error, 'Ocorreu um erro ao cadastrar!');
			} else {
				toastr.success('Registro cadastrado com sucesso!');
			}
		});
	} catch (error) {
		toastr.error(
			new Error(
				error?.response?.data?.message ||
				error?.data?.message ||
				error?.message ||
				error,
			),
		);
	}
};

export const UpdateVersions = async (versionKey, payload) => {
	try {
		const update = {};
		update[`friday/versions/${versionKey}`] = payload;
		await db.ref().update(update);
		toastr.success('Registro atualizado com sucesso!');
	} catch (error) {
		toastr.error(
			new Error(
				error?.response?.data?.message ||
				error?.data?.message ||
				error?.message ||
				error,
			),
		);
	}
};

export const DeleteVersion = async key => {
	try {
		await db.ref(`friday/versions/${key}`).remove();
		toastr.success('Registro deletado com sucesso!');
	} catch (error) {
		toastr.error(
			new Error(
				error?.response?.data?.message ||
				error?.data?.message ||
				error?.message ||
				error,
			),
			'Ocorreu um erro ao deletar!',
		);
	}
};
