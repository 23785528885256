export const CREATE_TRIP_CLEANER = 'CREATE_TRIP_CLEANER';
export const GET_WASHERS_SUCCESS = 'GET_WASHERS_SUCCESS';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_CLEANERS_SUCCESS = 'GET_CLEANERS_SUCCESS';
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';

export const createTripCleaner = data => ({
	type: CREATE_TRIP_CLEANER,
	payload: data,
});

export const getCleanersSuccess = data => ({
	type: GET_CLEANERS_SUCCESS,
	payload: data,
});

export const getVehiclesForWashSuccess = data => ({
	type: GET_VEHICLES_SUCCESS,
	payload: data,
});

export const getWashersSuccess = data => ({
	type: GET_WASHERS_SUCCESS,
	payload: data,
});

export const getCompaniesSuccess = data => ({
	type: GET_COMPANIES_SUCCESS,
	payload: data,
});
