import {UPDATE_VERSIONS, UPDATE_NEW_VERSIONS} from './actions';

const initialState = {
	versions: JSON.parse(localStorage.getItem('versions')) || [],
	newVersions: [],
	total: 0,
};

export default function versionReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_VERSIONS: {
			const update = {
				...state,
				versions: action.payload.versions,
				newVersions: [],
				total: 0,
			};
			return update;
		}
		case UPDATE_NEW_VERSIONS: {
			const newVersions = action.payload.versions.filter(
				item => JSON.stringify(state.versions).indexOf(item.key) < 0,
			);
			localStorage.setItem('versions', JSON.stringify(action.payload.versions));
			const update = {
				...state,
				...action.payload,
				newVersions: [...state.newVersions, ...newVersions],
				total: state.total + newVersions.length,
			};
			return update;
		}
		default: {
			return {
				...state,
			};
		}
	}
}
