import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import MaterialList from '@material-ui/core/List';
import theme from '../../theme';

const StyledListItem = styled(ListItem)`
	width: 240px !important;
	&:not(.secondary) {
		svg,
		span {
			color: ${theme.color.primary} !important;
		}
	}
	&.secondary {
		svg,
		span {
			color: ${theme.color.themeTerciary} !important;
		}
	}
`;

export const List = styled(MaterialList)`
	overflow-x: hidden !important;
	overflow-y: auto !important;
	height: 100%;
`;

export default StyledListItem;
