/* eslint-disable import/no-cycle */
import jwtDecode from 'jwt-decode';
import {AES} from 'crypto-js';
import toastr from 'services/Alerts';
import firebase, {FirebaseWashes, FirebaseDefault} from 'utils/firebase';
import {requestWithRefreshToken} from 'utils/api';

const apiURL = process.env.REACT_APP_CF_API;

const {auth} = firebase;

const firebaseProvider = new auth.GoogleAuthProvider();

function setTokenLocalStorage(token) {
	return new Promise((resolve, reject) => {
		try {
			window.localStorage.setItem('token', token);
			return resolve(token);
		} catch (error) {
			throw reject(error);
		}
	});
}

async function clearStorages() {
	try {
		await caches.keys().then(cacheNames => {
			return Promise.all(
				cacheNames.map(cacheName => {
					return caches.delete(cacheName);
				}),
			);
		});
		sessionStorage.clear();
		localStorage.clear();
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const signout = () => {
	return new Promise((resolve, reject) => {
		return clearStorages()
			.then(() =>
				Promise.all([
					FirebaseWashes.auth().signOut(),
					FirebaseDefault.auth().signOut(),
				]),
			)
			.then(resolve)
			.catch(error => {
				toastr.error(error);
				reject(error);
			});
	});
};

export const authListenerFireWashes = () => {
	try {
		return new Promise((resolve, reject) => {
			FirebaseWashes.auth().onAuthStateChanged(user => {
				if (!user) {
					return reject();
				}
				user
					.getIdToken(true)
					.then(resolve)
					.catch(reject);
			});
		});
	} catch (error) {
		console.log(error);
	}
};

export const getOperator = async () => {
	try {
		const response = await requestWithRefreshToken(
			apiURL,
			'turbiU/operators/operator/0',
			'get',
		);
		return response?.data[0];
	} catch (error) {
		toastr.error(
			new Error(error.response?.data?.message || error.message),
			'Erro na consulta do perfil de acesso',
		);
		throw error;
	}
};

export async function getRolesAndActionsOnIdentity() {
	try {
		const response = await requestWithRefreshToken(
			apiURL,
			'/identity_api/operator/me',
			'get',
			false,
			{},
			{
				'X-TURBI-KEY': process.env.REACT_APP_APPLICATION_ID_ACL,
			},
		);

		const identity = response.data;
		const aes = AES.encrypt(
			JSON.stringify(identity),
			process.env.REACT_APP_APPLICATION_ID_ACL,
		).toString();
		localStorage.setItem('identity', aes);
		return identity;
	} catch (err) {
		toastr.error(
			new Error(err.message),
			'Erro na consulta do perfil de acesso!',
		);
		throw err;
	}
}

async function signPersist(user) {
	const identity = await getRolesAndActionsOnIdentity();
	const operator = {
		id: identity.id,
		pushid: identity.uuid,
		name: identity.name,
		email: identity.email,
		roles: [],
	};
	
	localStorage.setItem('operator', JSON.stringify(operator));
	return {...operator, photoURL: user.photoURL};
}

export const onAuthStateChanged = () => {
	return new Promise((resolve, reject) => {
		const Auth = FirebaseDefault.auth();
		Auth.onAuthStateChanged(user => {
			if (!user) {
				signout();
				return reject();
			}
			user
				.getIdToken(true)
				.then(token => setTokenLocalStorage(token))
				.then(() => signPersist(user))
				.then(operators => resolve(operators))
				.catch(error => {
					if (
						error.response?.status === 401 ||
						error.response?.status === 403
					) {
						signout();
						toastr.report(new Error(error));
						throw reject(error);
					}
				});
		});
	});
};

export async function getTokenAuth() {
	try {
		const token = window.localStorage.getItem('token');
		const Auth = FirebaseDefault.auth();
		if (!Auth.currentUser) {
			return Error('Usuário não logado');
		}

		if (!token || new Date().getTime() > (jwtDecode(token).exp - 120) * 1000) {
			const newToken = await Auth.currentUser.getIdToken(true);
			setTokenLocalStorage(newToken);
			return newToken;
		}
		return token;
	} catch (error) {
		const token = window.localStorage.getItem('token');
		toastr.report('ERRO AO ATUALIZAR O TOKEN DE ACESSO', {
			additionalInformation: {
				error: {
					name: error.name,
					message: error.message,
				},
				token,
				time: new Date().getTime(),
			},
		});
		signout();
		throw error;
	}
}

export const SignInGoogle = async () => {
	try {
		const data = await FirebaseDefault.auth().signInWithPopup(firebaseProvider);
		await FirebaseWashes.auth().signInWithCredential(data.credential);
		const userEmail = data?.user.email;
		const idxUserEmail = userEmail.indexOf('@turbi.com.br');
		if (idxUserEmail > -1) {
			return onAuthStateChanged();
		}
		throw new Error('É necessário uma conta Turbi para login');
	} catch (error) {
		toastr.error(
			new Error(error.response?.data?.message || error.message || error),
			'Oops, ocorreu algo de errado',
		);
		throw error;
	}
};

/**
 * Login for accounts using email and password
 * @param {*} emailAddress
 * @param {*} password
 */
export const SignInWithEmailAndPassword = async (emailAddress, password) => {
	try {
		if (!emailAddress || !password) {
			throw new Error('Email ou senha inválidos');
		}

		if (FirebaseDefault.auth().currentUser) {
			throw new Error('Usuário já está logado');
		}

		const {user} = await FirebaseDefault.auth().signInWithEmailAndPassword(
			emailAddress,
			password,
		);

		if (!user) {
			throw new Error(
				'Verifique se sua conta não foi suspensa ou entre com credenciais válidas!',
			);
		}
		const {uid} = user;
		if (!uid) {
			throw new Error(
				'Verifique se sua conta não foi suspensa ou entre com uma credencial válida!',
			);
		}
		return onAuthStateChanged();
	} catch (error) {
		toastr.error(
			new Error(error.response?.data?.message || error.message || error),
			'Oops, ocorreu algo de errado no login por email e senha',
		);
		window.history.push('/');
	}
};

export const sendSignInLinkToEmail = async emailAddress => {
	try {
		if (!emailAddress) {
			throw new Error(
				'O campo E-mail precisa estar preenchido com um endereço válido.',
			);
		}

		if (FirebaseDefault.auth().currentUser) {
			throw new Error('Usuário já está logado');
		}

		const actionCodeSettings = {
			url: process.env.REACT_APP_HOMEPAGE,
			handleCodeInApp: true,
		};

		await FirebaseDefault.auth().sendSignInLinkToEmail(
			emailAddress,
			actionCodeSettings,
		);
		return true;
	} catch (error) {
		toastr.error(
			new Error(error.response?.data?.message || error.message || error),
			'Oops, ocorreu algo de errado ao solicitar link de login',
		);
		throw error;
	}
};
