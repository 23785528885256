/* eslint-disable no-param-reassign */
import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';
import reportWebVitals from '../../reportWebVitals';

export class DataDog {
	constructor() {
		if (!DataDog._instance) {
			DataDog._instance = this;
			this.clientToken = process.env.REACT_APP_CLIENT_TOKEN_DD;
			this.applicationId = process.env.REACT_APP_APPLICATION_ID_DD;
			this.site = process.env.REACT_APP_SITE_DD;
			this.service = process.env.REACT_APP_SERVICE_DD;
			this.datadogRum = datadogRum;
			this._datadogLogs = datadogLogs;
			this.execute();
		}
		return DataDog._instance;
	}

	get configInitilizeDD() {
		return {
			applicationId: this.applicationId,
			clientToken: this.clientToken,
			site: this.site,
			service: this.service,
			version: process.env.REACT_APP_VERSION_APPLICATION,
			sampleRate: 100,
		};
	}

	ddRumBuilder() {
		this.datadogRum.init({
			...this.configInitilizeDD,
			trackInteractions: true,
			defaultPrivacyLevel: 'allow',
			allowedTracingOrigins: [
				`${process.env.REACT_APP_USER_API}`,
				`${process.env.REACT_APP_CF_API}`,
				`${process.env.REACT_APP_TURBI_TRIP_API}`,
				`${process.env.REACT_APP_FLEET_API}`,
				`${process.env.REACT_APP_BOFFICE_OPERATION_API}`,
				`${process.env.REACT_APP_BACK_OFFICE_OPERATION_API}`,
				`${process.env.REACT_APP_COMMUNICATION_API}`,
				`${process.env.REACT_APP_ERROR_MONITOR}`,
				`${process.env.REACT_APP_HOMEPAGE}`,
			],
			beforeSend: (event, context) => {
				// collect a RUM resource's response headers
				if (event.type === 'error') {
					event.context = {
						...(event.context || {}),
						stackError: context?.error?.stack,
						messageError: context?.error?.message,
					};
					console.log(event, context);
					const {error} = event;
					const {message} = error;
					this._datadogLogs.logger.error(
						`user:${event?.usr?.email} error: ${message} in page:${event.view.url} and stack: ${context?.error?.stack}`,
						event,
					);
				}
				if (event.type === 'resource' && event.resource.type === 'xhr') {
					event.context = {
						...event.context,
						responseHeaders: context?.xhr?.response?.headers,
						responseBody: context?.xhr?.response?.body,
						response: context?.xhr?.response,
					};
					this._datadogLogs.logger.debug(
						`user:${event?.usr?.email} xhr api:${event.resource.url} in page:${event.view.url}`,
						event,
					);
				}
			},
		});
	}

	ddLogBuilder() {
		this._datadogLogs.init({
			...this.configInitilizeDD,
			forwardErrorsToLogs: false,
			forwardReports: 'all',
		});
	}

	debuggerWebVitals() {
		if (process.env.NODE_ENV !== 'production') {
			reportWebVitals(console.log);
		}
	}

	factory() {
		this.ddLogBuilder();
		this.ddRumBuilder();
		this.startSessionRec();
	}

	startSessionRec() {
		this.datadogRum.startSessionReplayRecording();
	}

	execute() {
		if (process.env.NODE_ENV === 'production') {
			if (window.location.hostname !== 'hml-friday.firebaseapp.com') {
				this.factory();
			}
		} else {
			this.debuggerWebVitals();
		}
	}
}

export default new DataDog();
