/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

Number.prototype.formatKm = function() {
	return (this < 0.5
		? new Intl.NumberFormat('pt-br', {
				style: 'unit',
				unit: 'meter',
		  })
		: new Intl.NumberFormat('pt-br', {
				style: 'unit',
				unit: 'kilometer',
		  })
	).format(this < 0.5 ? this.toFixed(3) : this.toFixed(2));
};

Number.prototype.formatKms = Number.prototype.formatKm;

Number.prototype.formatMoney = function() {
	return this.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	});
};

String.prototype.formatMoney = function() {
	return Number(this).toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	});
};

String.prototype.formatUtc = function(timezone = null) {
	return this
		? moment(this)
				.utc(timezone)
				.format('DD/MM/YY HH:mm')
		: '';
};

String.prototype.formatDate = function(withHours = true) {
	if (this)
		return withHours
			? moment(this).format('DD/MM/YY HH:mm')
			: moment(this).format('DD/MM/YY');
	return '';
};

String.prototype.getExtension = function() {
	const cleared =
		(this && (this?.includes('?') ? this?.split('?')[0] : this)) ||
		(this?.includes('?') ? this.split('?')[0] : this);
	return cleared && cleared?.split('.')[cleared?.split('.').length - 1];
};

Number.prototype.validationNegative = function() {
	return Math.sign(this) === -1 ? Number(Math.abs(this)) : Number(this);
};

Number.prototype.validationNAN = function() {
	if (Number.isNaN(Number(this))) {
		return 1;
	}
	return Number(this);
};

export default this;

export const useQuery = () => {
	return new URLSearchParams(window.location.search);
};
