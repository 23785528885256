import React, {useMemo} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {HasRoles} from 'common/ACL';
import {useAuth} from '../ProviderAuth/ProviderAuth';

function PrivateRoute({roles = [], role = '', component, ...props}) {
	const {user} = useAuth();

	const getRender = useMemo(() => {
		const allowed = !roles ? true : HasRoles(roles, role);
		if (user) {
			if (allowed) {
				return component;
			}
			return <Redirect to="/unauthorized" />;
		}
		return (
			<Redirect
				to={{
					pathname: '/login',
				}}
			/>
		);
	}, [user, roles]);

	return <Route {...props} render={() => getRender} />;
}

export default PrivateRoute;
