// eslint-disable-next-line import/no-cycle
import store from 'store';

export const GET_DAMAGES_BEGIN = 'GET_DAMAGES_BEGIN';
export const GET_DAMAGES_SUCCESS = 'GET_DAMAGES_SUCCESS';
export const GET_DAMAGES_FAILURE = 'GET_DAMAGES_FAILURE';
export const GET_SIMILARS_BEGIN = 'GET_SIMILARS_BEGIN';
export const GET_SIMILARS_SUCCESS = 'GET_SIMILARS_SUCCESS';
export const GET_SIMILARS_FAILURE = 'GET_SIMILARS_FAILURE';
export const SEND_IS_NOT_SIMILAR = 'SEND_IS_NOT_SIMILAR';
export const PUT_REPORT_ARCHIVED_BEGIN = 'PUT_REPORT_ARCHIVED_BEGIN';
export const PUT_REPORT_ARCHIVED_SUCCESS = 'PUT_REPORT_ARCHIVED_SUCCESS';
export const PUT_REPORT_ARCHIVED_FAILURE = 'PUT_REPORT_ARCHIVED_FAILURE';
export const PATCH_SIMILAR_AS_DAMAGE_FAILURE =
	'PATCH_SIMILAR_AS_DAMAGE_FAILURE';
export const PATCH_SIMILAR_AS_DAMAGE_BEGIN = 'PATCH_SIMILAR_AS_DAMAGE_BEGIN';
export const PATCH_SIMILAR_AS_DAMAGE_SUCCESS =
	'PATCH_SIMILAR_AS_DAMAGE_SUCCESS';
export const PUT_CREATE_DAMAGE_BEGIN = 'PUT_CREATE_DAMAGE_BEGIN';
export const PUT_CREATE_DAMAGE_SUCCESS = 'PUT_CREATE_DAMAGE_SUCCESS';
export const PUT_CREATE_DAMAGE_FAILURE = 'PUT_CREATE_DAMAGE_FAILURE';
export const PUT_UPDATE_DAMAGE_BEGIN = 'PUT_UPDATE_DAMAGE_BEGIN';
export const PUT_UPDATE_DAMAGE_SUCCESS = 'PUT_UPDATE_DAMAGE_SUCCESS';
export const PUT_UPDATE_DAMAGE_FAILURE = 'PUT_UPDATE_DAMAGE_FAILURE';
export const GET_LAST_TRIPS_BEGIN = 'GET_LAST_TRIPS_BEGIN';
export const GET_LAST_TRIPS_SUCCESS = 'GET_LAST_TRIPS_SUCCESS';
export const GET_LAST_TRIPS_FAILURE = 'GET_LAST_TRIPS_FAILURE';
export const GET_REPORT_DATE = 'GET_REPORT_DATE';

export const getDamagesBegin = () => ({
	type: GET_DAMAGES_BEGIN,
});

export const getDamagesSuccess = data => ({
	type: GET_DAMAGES_SUCCESS,
	payload: {data},
});

export const getReportDate = data => ({
	type: GET_REPORT_DATE,
	payload: {data},
});

export const getDamagesFailure = error => ({
	type: GET_DAMAGES_FAILURE,
	payload: {error},
});

export const getSimilarsBegin = () => ({
	type: GET_SIMILARS_BEGIN,
});

export const getSimilarsSuccess = data => ({
	type: GET_SIMILARS_SUCCESS,
	payload: {data},
});

export const getSimilarsFailure = error => ({
	type: GET_SIMILARS_FAILURE,
	payload: {error},
});

export const sendIsNotSimilar = data => ({
	type: SEND_IS_NOT_SIMILAR,
	payload: {data},
});

export const putReportArchivedBegin = () => ({
	type: PUT_REPORT_ARCHIVED_BEGIN,
});

export const putReportArchivedSuccess = data => ({
	type: PUT_REPORT_ARCHIVED_SUCCESS,
	payload: {data},
});

export const putReportArchivedFailure = error => ({
	type: PUT_REPORT_ARCHIVED_FAILURE,
	payload: {error},
});

export const patchSimilarAsDamageBegin = () => ({
	type: PATCH_SIMILAR_AS_DAMAGE_BEGIN,
});

export const patchSimilarAsDamageSuccess = data => ({
	type: PATCH_SIMILAR_AS_DAMAGE_SUCCESS,
	payload: {data},
});

export const patchSimilarAsDamageFailure = error => ({
	type: PATCH_SIMILAR_AS_DAMAGE_FAILURE,
	payload: {error},
});

export const putCreateDamageBegin = data => ({
	type: PUT_CREATE_DAMAGE_BEGIN,
	payload: {data},
});

export const putCreateDamageSuccess = data => ({
	type: PUT_CREATE_DAMAGE_SUCCESS,
	payload: {data},
});

export const putCreateDamageFailure = error => ({
	type: PUT_CREATE_DAMAGE_FAILURE,
	payload: {error},
});

export const putUpdateDamageBegin = () => ({
	type: PUT_UPDATE_DAMAGE_BEGIN,
});

export const putUpdateDamageSuccess = data => ({
	type: PUT_UPDATE_DAMAGE_SUCCESS,
	payload: {data},
});

export const putUpdateDamageFailure = error => ({
	type: PUT_UPDATE_DAMAGE_FAILURE,
	payload: {error},
});

export const getLastTripsBegin = () => ({
	type: GET_LAST_TRIPS_BEGIN,
});

export const getLastTripsSuccess = data => ({
	type: GET_LAST_TRIPS_SUCCESS,
	payload: {data},
});

export const getLastTripsFailure = error => ({
	type: GET_LAST_TRIPS_FAILURE,
	payload: {error},
});

export const updateDamageItem = (reportId, createdDamage) => dispatch => {
	const state = store.getState();
	const damages = state.damages.data;
	const damage = damages.find(d => d.reportId === reportId);

	if (damage) {
		damage.damage = createdDamage;
	}

	dispatch(getDamagesSuccess(damages));
};
