import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
	// Drawer,
	CssBaseline,
	Grid,
	Avatar,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from '@material-ui/core';
import {
	ExitToApp as SignOut,
	Menu as MenuIcon,
	Close,
} from '@material-ui/icons';
import {useAuth} from 'services/ProviderAuth/ProviderAuth';
import MainMenu from '../MainMenu';
import styles from './styles';
import logo from './logo.png';
import {
	VersionCode,
	VersionContainer,
	Drawer,
	AppBar,
	FlagConnectionUser,
	MenuButton,
} from './styledAppBar';
import theme from '../../theme';

function AppBarDrawer({classes, versionApplication}) {
	const [menuOpened, openMenu] = useState(false);
	const [asideOpened, openAside] = useState(false);
	const [avatarElement, setAvatarElement] = useState();
	const [userName, setUserName] = useState('');
	const [avatar, setAvatar] = useState('');
	const [online, isOnline] = useState(window.navigator.onLine);
	const {signout, user} = useAuth();

	useEffect(() => {
		if (user) {
			setUserName(user.name);
			setAvatar(user.photoURL); // falta pegar da sdk
		}

		if (window.addEventListener) {
			window.addEventListener(
				'online',
				() => {
					isOnline(true);
				},
				true,
			);
			window.addEventListener(
				'offline',
				() => {
					isOnline(false);
				},
				true,
			);
		} else if (window.attachEvent) {
			window.attachEvent('ononline', () => {
				isOnline(true);
			});
			window.attachEvent('onoffline', () => {
				isOnline(false);
			});
		} else {
			window.ononline = () => {
				isOnline(true);
			};
			window.onoffline = () => {
				isOnline(false);
			};
		}
	}, [user]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={`${asideOpened ? 'menu-opened' : ''} ${classes.appBar}`}
				style={{background: theme.color.primary}}
			>
				<Grid
					item
					container
					spacing={2}
					style={{
						alignItems: 'center',
					}}
				>
					<Grid
						item
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<MenuButton onClick={() => openAside(!asideOpened)}>
							{!asideOpened ? <MenuIcon /> : <Close />}
						</MenuButton>
						<Link to="/">
							<img loading="lazy" src={logo} alt="Turbi Logo" width="150px" />
						</Link>
					</Grid>
					{user && (
						<Grid
							item
							container
							xs
							spacing={2}
							style={{
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<Grid item>
								<Typography
									variant="subtitle2"
									style={{
										color: online ? theme.color.success : theme.color.error,
									}}
								>
									<FlagConnectionUser online={online} />
									<span className="typo">
										Você está {online ? 'online' : 'offline'}
									</span>
								</Typography>
							</Grid>

							<Grid item>
								<IconButton
									onClick={() => openMenu(true)}
									ref={el => setAvatarElement(el)}
									style={{
										padding: 0,
									}}
								>
									<Avatar alt="Foto de algum Turbiano" src={avatar} />
								</IconButton>
								<Menu
									anchorEl={avatarElement}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									keepMounted
									open={menuOpened}
									onClose={() => openMenu(false)}
									style={{
										transform: 'translateY(38px)',
										zIndex: 10000,
									}}
								>
									<Grid container spacing={4} style={{margin: 0}}>
										<Grid item>
											<Typography variant="body1">
												<b>Olá, {userName?.split(' ')[0]}</b>
											</Typography>
										</Grid>
									</Grid>
									<MenuItem onClick={() => signout()}>
										Sair
										<SignOut fontSize="small" style={{marginLeft: 8}} />
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					)}
				</Grid>
			</AppBar>
			{user && (
				<Drawer className={asideOpened ? 'opened' : ''}>
					<MainMenu />
					<VersionContainer>
						<VersionCode>{versionApplication}</VersionCode>
					</VersionContainer>
				</Drawer>
			)}
		</div>
	);
}

AppBarDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	versionApplication: PropTypes.string.isRequired,
};

const mapStateToProps = () => {
	return {
		versionApplication: process.env.REACT_APP_VERSION_APPLICATION,
	};
};

export default connect(mapStateToProps)(
	withStyles(styles, {withTheme: true})(withRouter(AppBarDrawer)),
);
