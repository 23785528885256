/* eslint-disable no-nested-ternary */
import {combineReducers, createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {routerMiddleware, connectRouter} from 'connected-react-router';
import {
	damagesReducer,
	lastTripsReducer,
	similarsDamage,
} from 'pages/Operations/DamagesReport/damagesReducer';

import {
	usersReducer,
	usersAnalysisReducerGraphic,
	usersRegisterReducerGraphic,
	analysisTimeReducer,
} from 'pages/Users/Users/usersReducer';
import {reservationsReducer} from './pages/Reservations/templates/SummaryReservations/services/reducer';
import CleanerReducer from './pages/Operations/Wash/store/Cleaner.reducer';
import RentsReducer from './pages/Vehicles/Rents/reducer';
import versionsReducer from './pages/Versions/services/store/reducer';

import alertsReducer from './services/Alerts/reducer';
import history from './services/Routes/history';

import {LoadingReducer} from './common/Loading/LoadingReducer';

const RootReducer = combineReducers({
	router: connectRouter(history),
	Loading: LoadingReducer,
	reservations: reservationsReducer,
	cleaners: CleanerReducer,
	rents: RentsReducer,
	damages: damagesReducer,
	lastTrips: lastTripsReducer,
	similars: similarsDamage,
	users: usersReducer,
	usersAnalysis: usersAnalysisReducerGraphic,
	usersRegister: usersRegisterReducerGraphic,
	analysisTime: analysisTimeReducer,
	versions: versionsReducer,
	alerts: alertsReducer,
});

const composeEnhancers =
	process.env.NODE_ENV === 'production'
		? compose(
				applyMiddleware(
					thunk,
					// logger,
					routerMiddleware(history),
				),
		  )
		: !window.__REDUX_DEVTOOLS_EXTENSION__
		? compose(
				applyMiddleware(
					thunk,
					// logger,
					routerMiddleware(history),
				),
		  )
		: compose(
				applyMiddleware(
					thunk,
					// logger,
					routerMiddleware(history),
				),
				window.__REDUX_DEVTOOLS_EXTENSION__ &&
					window.__REDUX_DEVTOOLS_EXTENSION__(),
		  );

const store = createStore(RootReducer, composeEnhancers);

export default store;
