/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {AES, enc} from 'crypto-js';
import {Grid, Typography} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Roles from './Roles';

function _permissions(permissions) {
	try {
		const operator = JSON.parse(localStorage.getItem('operator'));
		const aes = localStorage.getItem('identity');
		const _identity = AES.decrypt(
			aes,
			process.env.REACT_APP_APPLICATION_ID_ACL,
		).toString(enc.Utf8);
		const identity = JSON.parse(_identity);

		if (!operator || !identity.actions) return false;
		const checkPermissions = permissions.filter(permission =>
			identity.actions.find(action => action === permission),
		);
		return checkPermissions.length > 0;
	} catch (error) {
		return false;
	}
}

function _roles(roles) {
	try {
		const operator = JSON.parse(localStorage.getItem('operator'));
		const aes = localStorage.getItem('identity');
		const _identity = AES.decrypt(
			aes,
			process.env.REACT_APP_APPLICATION_ID_ACL,
		).toString(enc.Utf8);
		const identity = JSON.parse(_identity);
		if (!operator || !identity.roles) return false;
		const checkRoles = roles.filter(role =>
			identity.roles.find(r => r.name === role),
		);
		return checkRoles.length > 0;
	} catch (error) {
		return false;
	}
}

function NotAccessFromInformation({fullText, customText, gridProps, noIcon}) {
	return (
		<Grid {...gridProps} data-testid="no-show-data">
			<>
				{!noIcon && <LockIcon />}{' '}
				{fullText && <Typography variant="caption">{customText}</Typography>}
			</>
		</Grid>
	);
}

export function HasPermissions(permission = '', permissions = []) {
	const hasPermissions = _permissions([permission, ...permissions]);
	return hasPermissions;
}

export function HasRoles(roles = [], role = '') {
	const hasRoles = _roles([role, ...roles]);
	return hasRoles;
}

export function ProtectionToEmpployersEmailValidation(email = '') {
	if (HasRoles([Roles.people])) {
		return email.includes('@turbi.com.br');
	}
	return true;
}

function ACL({
	permission = '',
	permissions = [],
	roles = [],
	fullText = false,
	noIcon = false,
	customText = `Solicite permissão para visualizar essa informação.`,
	children,
	gridProps = {},
}) {
	const hasPermissions = HasPermissions(permission, permissions);
	const hasRoles = HasRoles(roles);

	return (
		<>
			{hasPermissions || hasRoles ? (
				children
			) : (
				<NotAccessFromInformation
					fullText={fullText}
					customText={customText}
					gridProps={gridProps}
					noIcon={noIcon}
				/>
			)}
		</>
	);
}

ACL.propTypes = {
	children: PropTypes.node.isRequired,
	permission: PropTypes.string.isRequired,
	permissions: PropTypes.arrayOf(PropTypes.string),
	fullText: PropTypes.bool,
	customText: PropTypes.string,
	gridProps: PropTypes.object,
	noIcon: PropTypes.bool,
};

export default ACL;
