import {ON_LOADING, OFF_LOADING} from './LoadingActions';

const initialState = {
	loading: false,
	sucessMsg: '',
	errorMsg: '',
};

// eslint-disable-next-line import/prefer-default-export
export function LoadingReducer(state = initialState, action) {
	switch (action.type) {
		case ON_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case OFF_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}
