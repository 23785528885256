import React from 'react';
import Icon from '@mdi/react';
// import { mdiApple as AppleIcon } from "@mdi/js";
// import { mdiFacebook as FacebookIcon } from "@mdi/js";
// import { Github as GitHubIcon } from "@mdi/js";
import {mdiGoogle} from '@mdi/js';
// import { mdiMicrosoft as MicrosoftIcon } from "@mdi/js";
// import { mdiTwitter as TwitterIcon } from "@mdi/js";
// import { mdiYahoo as YahooIcon } from "@mdi/js";

const authProviders = [
	// {
	//   id: "apple.com",
	//   color: "#000000",
	//   icon: <AppleIcon />,
	//   name: "Apple",
	// },
	// {
	//   id: "facebook.com",
	//   color: "#3c5a99",
	//   icon: <FacebookIcon />,
	//   name: "Facebook",
	// },
	// {
	//   id: "github.com",
	//   color: "#24292e",
	//   icon: <GitHubIcon />,
	//   name: "GitHub",
	//   scopes: ["repo"],
	// },
	{
		id: 'google.com',
		color: '#4285f4',
		icon: <Icon path={mdiGoogle} title="Google" size={1} />,
		name: 'Google',
	},
	// {
	//   id: "microsoft.com",
	//   color: "#f65314",
	//   icon: <MicrosoftIcon />,
	//   name: "Microsoft",
	// },
	// {
	//   id: "twitter.com",
	//   color: "#1da1f2",
	//   icon: <TwitterIcon />,
	//   name: "Twitter",
	// },
	// {
	//   id: "yahoo.com",
	//   color: "#410093",
	//   icon: <YahooIcon />,
	//   name: "Yahoo",
	// },
];

export default authProviders;
