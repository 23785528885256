import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import MaterialAppBar from '@material-ui/core/AppBar';
import theme from '../../theme';
import {maxMedia} from '../../utils/mediaTemplate';

export const StyledGrid = styled(Grid)`
	&& {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 20px;
		${maxMedia.phone` padding-right: 10px;`}
	}
`;

export const VersionCode = styled.span`
	display: block;
	border-radius: 3px;
	background: rgba(0, 0, 0, 0.3);
	font-size: 0.8em;
	font-weight: bold;
`;

export const VersionContainer = styled.div`
	left: 0;
	bottom: 0;
	position: absolute;
	padding: 4px;
	width: 100%;
	box-sizing: border-box;
	text-align: center;
`;

export const StyledTypography = styled(Typography)`
	&& {
		color: ${theme.color.primary};
		margin-right: 20px;
		${maxMedia.phone`display:none`}
	}
`;

export const StyledGridContainer = styled(Grid)`
	&& {
		justify-content: space-between;
		flex-wrap: nowrap;
	}
`;

export const StyledMenuGrid = styled(Grid)`
	&& {
		display: flex;
	}
`;

export const StyledDiv = styled(Grid)`
	&& {
		width: 200px;
		height: 39px;
		margin-left: 15px;
	}
`;

export const StyledIconButton = styled(IconButton)`
	&& {
		padding: 0 0 0 13px;
	}
`;

export const StyledAvatar = styled(Avatar)`
	&& {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
`;

export const Drawer = styled.aside`
	position: fixed;
	bottom: 0;
	left: 0;
	height: calc(100% - 50px);
	width: ${8 * 7}px;
	z-index: 110;
	background: #2b2b2b;
	color: ${theme.color.primary};
	transition: 0.5s ease-in-out;
	overflow: hidden;
	border-top-right-radius: 6px;
	padding: 0 0 8px;

	svg {
		color: ${theme.color.primary};
	}

	@media screen and (min-width: ${theme.breakpoints.md + 1}px) {
		&:hover {
			width: 240px;
			background: ${theme.color.themePrimary};
		}
	}

	@media screen and (max-width: ${theme.breakpoints.md}px) {
		width: 240px;
		left: -240px;
		height: 100%;
		&.opened {
			left: 0;
			background: ${theme.color.themePrimary};
		}
	}
`;

export const MenuButton = styled(IconButton)`
	display: none !important;
	margin-left: -16px !important;
	@media screen and (max-width: ${theme.breakpoints.md}px) {
		display: initial !important;
	}
`;

export const FlagConnectionUser = styled.span`
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 8px;
	background-color: ${({color}) => (color)};
	border-radius: 10px;
	animation: mymove 2s infinite;
	animation-timing-function: linear;

	@media (max-width: ${theme.breakpoints.md}px) {
		& + span.typo {
			display: none;
		}
	}

	@keyframes mymove {
		from {
			opacity: 1;
		}
		to {
			opacity: 0.1;
		}
	}
	@keyframes mymove {
		40% {
			opacity: 0.3;
		}
		50% {
			opacity: 0.4;
		}
		60% {
			opacity: 0.5;
		}
		70% {
			opacity: 0.6;
		}
		80% {
			opacity: 0.7;
		}
		90% {
			opacity: 0.8;
		}
		95% {
			opacity: 0.9;
		}
		100% {
			opacity: 1;
		}
	}
`;

export const AppBar = styled(MaterialAppBar)`
	@media screen and (max-width: ${theme.breakpoints.md}px) {
		transition: 0.5s !important;
		&.menu-opened {
			transform: translateX(240px);
		}
	}
`;

export const AlertBullet = styled.small`
	position: absolute;
	right: 10px;
	top: 10px;
	background: ${theme.color.error};
	border-radius: 100%;
	width: 14px;
	height: 14px;
	color: ${theme.color.primary};
	font-weight: bold;
	font-size: 10px;
`;
