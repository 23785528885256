import {
	GET_USERS_BEGIN,
	GET_USERS_SUCCESS,
	GET_USERS_FAILURE,
	GET_USERS_ANALYSIS_BEGIN,
	GET_USERS_ANALYSIS_SUCCESS,
	GET_USERS_ANALYSIS_FAILURE,
	GET_USERS_REGISTER_BEGIN,
	GET_USERS_REGISTER_SUCCESS,
	GET_USERS_REGISTER_FAILURE,
	GET_ANALYSIS_TIME_BEGIN,
	GET_ANALYSIS_TIME_SUCCESS,
	GET_ANALYSIS_TIME_FAILURE,
} from './usersAction';

const initialState = {
	data: [],
	isLoading: false,
	error: null,
};

export function usersReducer(state = initialState, action) {
	switch (action.type) {
		case GET_USERS_BEGIN:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case GET_USERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};

		case GET_USERS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
				data: [],
			};

		default:
			return state;
	}
}

export function usersAnalysisReducerGraphic(state = initialState, action) {
	switch (action.type) {
		case GET_USERS_ANALYSIS_BEGIN:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case GET_USERS_ANALYSIS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};
		}

		case GET_USERS_ANALYSIS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
				data: [],
			};

		default:
			return state;
	}
}

export function usersRegisterReducerGraphic(state = initialState, action) {
	switch (action.type) {
		case GET_USERS_REGISTER_BEGIN:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case GET_USERS_REGISTER_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};

		case GET_USERS_REGISTER_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
				data: [],
			};

		default:
			return state;
	}
}

export function analysisTimeReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ANALYSIS_TIME_BEGIN:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case GET_ANALYSIS_TIME_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
			};

		case GET_ANALYSIS_TIME_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
				data: [],
			};

		default:
			return state;
	}
}
