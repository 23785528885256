/* eslint-disable import/no-cycle */
import {
	GET_RENTS_SUCCESS,
	CREATE_TRIP_CLEANER,
	GET_COMPANIES_SUCCESS,
	GET_WASHERS_SUCCESS,
	GET_VEHICLES_SUCCESS,
} from './services';

const initialState = {
	data: [],
};

const RentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_TRIP_CLEANER:
			return {
				...state,
				data: action.payload.data,
			};
		case GET_RENTS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
			};
		case GET_WASHERS_SUCCESS:
			return {
				...state,
				washers: action.payload.data,
			};
		case GET_COMPANIES_SUCCESS:
			return {
				...state,
				companies: action.payload.data,
			};
		case GET_VEHICLES_SUCCESS:
			return {
				...state,
				vehicles: action.payload.data,
			};
		default:
			return state;
	}
};

export default RentsReducer;
