import {UPDATE_ALERTS} from './typing';

const initialState = [];

const alertsReducer = (state = initialState, {type, value}) => {
	const typings = {
		[UPDATE_ALERTS]: value,
		default: state,
	};

	return typings[type] || typings.default;
};

export default alertsReducer;
