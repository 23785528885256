export const GET_VEHICLE_INFO_BEGIN = 'GET_VEHICLE_INFO_BEGIN';
export const GET_VEHICLE_INFO_SUCCESS = 'GET_VEHICLE_INFO_SUCCESS';
export const GET_VEHICLE_INFO_FAILURE = 'GET_VEHICLE_INFO_FAILURE';

export const GET_ACTIVE_RESERVATIONS_BEGIN = 'GET_ACTIVE_RESERVATIONS_BEGIN';
export const GET_ACTIVE_RESERVATIONS_SUCCESS =
	'GET_ACTIVE_RESERVATIONS_SUCCESS';
export const GET_ACTIVE_RESERVATIONS_FAILURE =
	'GET_ACTIVE_RESERVATIONS_FAILURE';

export const START_VEHICLE_COMPONENT = 'START_VEHICLE_COMPONENT';
export const END_VEHICLE_COMPONENT = 'END_VEHICLE_COMPONENT ';

export const getVehicleInfoBegin = () => ({
	type: GET_VEHICLE_INFO_BEGIN,
});

export const getVehicleInfoSuccess = data => ({
	type: GET_VEHICLE_INFO_SUCCESS,
	payload: {data},
});

export const getVehicleInfoFailure = error => ({
	type: GET_VEHICLE_INFO_FAILURE,
	payload: {error},
});

export const getActivesBegin = () => ({
	type: GET_ACTIVE_RESERVATIONS_BEGIN,
});

export const getActivesFailure = error => ({
	type: GET_ACTIVE_RESERVATIONS_FAILURE,
	payload: {error},
});

export const startVehicleComponent = () => {
	return {
		type: START_VEHICLE_COMPONENT,
		payload: {},
	};
};

export const endVehicleComponent = () => ({
	type: END_VEHICLE_COMPONENT,
	payload: {},
});
