import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from 'react';
import {useHistory} from 'react-router-dom';
import LogRocket from 'logrocket';
import {datadogRum} from '@datadog/browser-rum';
import {FirebaseDefault} from 'utils/firebase';
import toastr from 'services/Alerts';
import {
	SignInGoogle,
	SignInWithEmailAndPassword,
	signout as signoutService,
	onAuthStateChanged,
} from './service/Auth.services';

const AuthContext = createContext({});

export function useAuth() {
	return useContext(AuthContext);
}

function getUserFirebase() {
	const currentUser = FirebaseDefault.auth().currentUser || null;
	if (currentUser) {
		const operator = JSON.parse(localStorage.getItem('operator'));
		return {...operator, photoURL: currentUser.photoURL};
	}
	return null;
}

export default function ProvideAuth({children}) {
	const handleHistory = useHistory();
	const [user, setUser] = useState(getUserFirebase());
	const [loadingGetUser, setLoadingGetUser] = React.useState(false);

	const getUser = useCallback(() => {
		setLoadingGetUser(true);
		onAuthStateChanged()
			.then(operators => {
				setUser(operators);
				const identify = {
					name: operators?.name,
					email: operators?.email,
					pushId: operators?.pushid,
					signin: new Date().toISOString(),
				};
				try {
					datadogRum.setUser(identify);
					LogRocket.identify(operators?.pushid, identify);
				} catch (error) {
					toastr.error(error);
				}
			})
			.catch(error => {
				toastr.error(error);
				setUser(null);
			})
			.finally(() => setLoadingGetUser(false));
	}, []);

	const signin = useCallback(() => {
		return SignInGoogle()
			.then(operators => {
				setUser(operators);
				return operators;
			})
			.catch(error => {
				setUser(null);
				setLoadingGetUser(false);
				throw error;
			});
	}, []);

	const signInWithEmailAndPassword = useCallback((email, password) => {
		return SignInWithEmailAndPassword(email, password)
			.then(operators => {
				setUser(operators);
				return operators;
			})
			.catch(error => {
				setUser(null);
				setLoadingGetUser(false);
				throw error;
			});
	}, []);

	const signout = useCallback(() => {
		return signoutService()
			.then(() => {
				setUser(null);
				if (window.location.pathname !== '/login') {
					handleHistory.push('/login');
				}
			})
			.catch(err => {
				toastr.error(err);
			});
	}, []);

	useEffect(() => getUser(), []);

	return (
		<AuthContext.Provider
			value={{
				user,
				signin,
				signout,
				signInWithEmailAndPassword,
				loadingGetUser,
				getUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
