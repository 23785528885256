/* eslint-disable import/prefer-default-export */
import {
	GET_VEHICLE_INFO_BEGIN,
	GET_VEHICLE_INFO_SUCCESS,
	GET_VEHICLE_INFO_FAILURE,
	START_VEHICLE_COMPONENT,
	END_VEHICLE_COMPONENT,
} from './action';

const initialState = {
	isLoading: false,
	error: null,
	data: [],
};

export function reservationsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VEHICLE_INFO_BEGIN:
			return {
				...state,
				isLoading: false,
				error: null,
			};
		case GET_VEHICLE_INFO_SUCCESS:
			return {
				...state,
				data: action.payload.data,
			};
		case GET_VEHICLE_INFO_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			};
		case START_VEHICLE_COMPONENT:
			return {
				...state,
				isLoading: true,
			};
		case END_VEHICLE_COMPONENT:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
}
