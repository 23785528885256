const config = {
	firebase: {
		apiKey: 'AIzaSyB39RZ5GHxDIl7N9t2lRRSsGYysq9FS-58',
		databaseURL: 'https://turbi-22388.firebaseio.com/',
		projectId: 'turbi-22388',
		storageBucket: 'turbi-22388.appspot.com',
		messagingSenderId: '759743656071',
		authDomain: 'turbi-22388.firebaseapp.com',
	},
	washesApp: {
		apiKey: 'AIzaSyC1MCjOZldbKuOW0-yHZE-KoP8HKqVnwms',
		authDomain: 'turbi-wash.firebaseapp.com',
		databaseURL: 'https://turbi-wash.firebaseio.com',
		projectId: 'turbi-wash',
		storageBucket: 'turbi-wash.appspot.com',
		messagingSenderId: '686427192196',
		appId: '1:686427192196:web:b06b26e975307e3f1f3bd1',
		measurementId: 'G-W9K8X83BYY',
	},
};

export default config;
