import moment from 'moment';
import firebase, {FirebaseDefault} from 'utils/firebase';
import errors from './errors';

const authentication = {};
const {auth, firestore, analytics} = firebase;

authentication.signUp = fields => {
	return new Promise((resolve, reject) => {
		if (!fields) {
			reject();
			return;
		}

		const {firstName} = fields;
		const {lastName} = fields;
		const {username} = fields;
		const {emailAddress} = fields;
		const {password} = fields;

		if (!firstName || !lastName || !username || !emailAddress || !password) {
			reject();
			return;
		}

		if (auth.currentUser) {
			reject();
			return;
		}

		FirebaseDefault.auth()
			.createUserWithEmailAndPassword(emailAddress, password)
			.then(value => {
				const {user} = value;
				if (!user) {
					reject();
					return;
				}

				const {uid} = user;

				if (!uid) {
					reject();
					return;
				}

				const userDocumentReference = firestore.collection('users').doc(uid);

				userDocumentReference
					.set({
						firstName,
						lastName,
						username,
					})
					.then(updatedValue => {
						analytics.logEvent('sign_up', {
							method: 'password',
						});
						resolve(updatedValue);
					})
					.catch(updatedReason => {
						reject(updatedReason);
					});
			})
			.catch(reason => {
				reject(reason);
			});
	});
};
/**
 * Create Account using Email and Password only without social integration
 * @param {*} emailAddress
 * @param {*} password
 */
authentication.signUpWithEmailAddressAndPassword = (emailAddress, password) => {
	return new Promise((resolve, reject) => {
		if (!emailAddress || !password) {
			reject();
			return;
		}

		if (auth.currentUser) {
			reject();
			return;
		}

		FirebaseDefault.auth()
			.createUserWithEmailAndPassword(emailAddress, password)
			.then(value => {
				const {user} = value;

				if (!user) {
					reject();
					return;
				}

				const {uid} = user;

				if (!uid) {
					reject();
					return;
				}

				const userDocumentReference = firestore.collection('users').doc(uid);

				userDocumentReference
					.set({}, {merge: true})
					.then(updatedValue => {
						analytics.logEvent('sign_up', {
							method: 'password',
						});
						resolve(updatedValue);
					})
					.catch(updatedReason => {
						reject(updatedReason);
					});
			})
			.catch(reason => {
				reject(reason);
			});
	});
};
/**
 * Login for accounts created using email and password only
 * @param {*} emailAddress
 * @param {*} password
 */
authentication.signIn = (emailAddress, password) => {
	return new Promise((resolve, reject) => {
		if (!emailAddress || !password) {
			reject();
			return;
		}

		if (auth.currentUser) {
			reject();
			return;
		}

		FirebaseDefault.auth()
			.signInWithEmailAndPassword(emailAddress, password)
			.then(value => {
				const {user} = value;

				if (!user) {
					reject();

					return;
				}

				const {uid} = user;

				if (!uid) {
					reject();

					return;
				}
				console.log('login with email and password!', user);
				resolve(user);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.sendSignInLinkToEmail = emailAddress => {
	return new Promise((resolve, reject) => {
		if (!emailAddress) {
			reject();
			return;
		}

		if (auth.currentUser) {
			reject();
			return;
		}

		const actionCodeSettings = {
			url: process.env.REACT_APP_HOMEPAGE,
			handleCodeInApp: true,
		};

		FirebaseDefault.auth()
			.sendSignInLinkToEmail(emailAddress, actionCodeSettings)
			.then(value => {
				localStorage.setItem('emailAddress', emailAddress);
				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.signInWithEmailLink = (emailAddress, emailLink) => {
	return new Promise((resolve, reject) => {
		if (!emailAddress || !emailLink) {
			reject();
			return;
		}

		if (auth.currentUser) {
			reject();
			return;
		}

		FirebaseDefault.auth()
			.signInWithEmailLink(emailAddress, emailLink)
			.then(value => {
				localStorage.removeItem('emailAddress');
				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.signInWithAuthProvider = provider => {
	return new Promise((resolve, reject) => {
		if (!provider) {
			reject();
			return;
		}

		const authProvider = new firebase.auth.OAuthProvider(provider.id);
		const {scopes} = provider;

		if (scopes) {
			scopes.forEach(scope => {
				authProvider.addScope(scope);
			});
		}

		if (auth.currentUser) {
			reject();
			return;
		}

		FirebaseDefault.auth()
			.signInWithPopup(authProvider)
			.then(res => {
				const {user} = res;

				if (!user) {
					reject();
					return;
				}
				const {uid} = user;
				if (!uid) {
					reject();
					return;
				}
				console.log('login with provider success!', user);
				resolve(user);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.linkAuthProvider = provider => {
	return new Promise((resolve, reject) => {
		if (!provider) {
			reject();
			return;
		}
		const authProvider = new firebase.auth.OAuthProvider(provider.id);
		const {scopes} = provider;
		if (scopes) {
			scopes.forEach(scope => {
				authProvider.addScope(scope);
			});
		}
		const {currentUser} = FirebaseDefault.auth();
		if (!currentUser) {
			reject();
			return;
		}

		currentUser
			.linkWithPopup(authProvider)
			.then(value => {
				// analytics.logEvent('link_auth_provider', {
				// 	providerId: authProvider.id,
				// });
				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.unlinkAuthProvider = providerId => {
	return new Promise((resolve, reject) => {
		if (!providerId) {
			reject();
			return;
		}
		const {currentUser} = FirebaseDefault.auth();
		if (!currentUser) {
			reject();
			return;
		}

		currentUser
			.unlink(providerId)
			.then(value => {
				analytics.logEvent('unlink_auth_provider', {
					providerId,
				});

				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.authProviderData = providerId => {
	if (!providerId) {
		return;
	}
	const {currentUser} = FirebaseDefault.auth();
	if (!currentUser) {
		return;
	}
	const {providerData} = currentUser;
	if (!providerData) {
		return;
	}
	return providerData.find(authProvider => authProvider.id === providerId);
};

authentication.signOut = () => {
	return new Promise((resolve, reject) => {
		const {currentUser} = FirebaseDefault.auth();
		if (!currentUser) {
			reject();
			return;
		}

		FirebaseDefault.auth()
			.signOut()
			.then(value => {
				analytics.logEvent('sign_out');

				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.resetPassword = emailAddress => {
	return new Promise((resolve, reject) => {
		if (!emailAddress) {
			reject();
			return;
		}

		if (auth.currentUser) {
			reject();
			return;
		}
		FirebaseDefault.auth()
			.sendPasswordResetEmail(emailAddress)
			.then(value => {
				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.changeEmailAddress = emailAddress => {
	return new Promise((resolve, reject) => {
		if (!emailAddress) {
			reject();

			return;
		}

		const {currentUser} = FirebaseDefault.auth();

		if (!currentUser) {
			reject();

			return;
		}

		const {uid} = currentUser;

		if (!uid) {
			reject();

			return;
		}

		currentUser
			.updateEmail(emailAddress)
			.then(value => {
				analytics.logEvent('change_email_address');

				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.changePassword = password => {
	return new Promise((resolve, reject) => {
		if (!password) {
			reject();
			return;
		}
		const {currentUser} = FirebaseDefault.auth();
		if (!currentUser) {
			reject();
			return;
		}
		const {uid} = currentUser;
		if (!uid) {
			reject();
			return;
		}

		currentUser
			.updatePassword(password)
			.then(value => {
				console.log('email changed', value);
				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.verifyEmailAddress = () => {
	return new Promise((resolve, reject) => {
		const {currentUser} = FirebaseDefault.auth();
		if (!currentUser) {
			reject();
			return;
		}

		currentUser
			.sendEmailVerification()
			.then(value => {
				console.log('verify email address', value);
				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.deleteAccount = () => {
	return new Promise((resolve, reject) => {
		const {currentUser} = FirebaseDefault.auth();
		if (!currentUser) {
			reject();
			return;
		}
		currentUser
			.delete()
			.then(value => {
				console.log('delete account', value);
				resolve(value);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.getRoles = () => {
	return new Promise((resolve, reject) => {
		const {currentUser} = FirebaseDefault.auth();
		if (!currentUser) {
			reject();
			return;
		}

		currentUser
			.getIdTokenResult()
			.then(idTokenResult => {
				resolve(idTokenResult.claims.roles);
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.isAdmin = () => {
	return new Promise((resolve, reject) => {
		authentication
			.getRoles()
			.then(value => {
				resolve(value.includes('admin'));
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.isPremium = () => {
	return new Promise((resolve, reject) => {
		authentication
			.getRoles()
			.then(value => {
				resolve(value.includes('premium'));
			})
			.catch(reason => {
				reject(reason);
			});
	});
};

authentication.getName = fields => {
	if (!fields) {
		return null;
	}

	const {firstName} = fields;
	const {username} = fields;
	const {displayName} = fields;
	const {lastName} = fields;

	if (firstName) {
		return firstName;
	}

	if (username) {
		return username;
	}

	if (displayName) {
		return displayName;
	}

	if (lastName) {
		return lastName;
	}

	return null;
};

authentication.getFullName = fields => {
	if (!fields) {
		return null;
	}

	const {firstName} = fields;
	const {lastName} = fields;
	const {displayName} = fields;

	if (firstName && lastName) {
		return `${firstName} ${lastName}`;
	}

	if (displayName) {
		return displayName;
	}

	return null;
};

authentication.getNameInitials = fields => {
	if (!fields) {
		return null;
	}

	const {firstName} = fields;
	const {lastName} = fields;
	const {username} = fields;
	const {displayName} = fields;

	if (firstName && lastName) {
		return firstName.charAt(0) + lastName.charAt(0);
	}

	if (firstName) {
		return firstName.charAt(0);
	}

	if (username) {
		return username.charAt(0);
	}

	if (lastName) {
		return lastName.charAt(0);
	}

	if (displayName) {
		return displayName.charAt(0);
	}

	return null;
};

authentication.getProfileCompletion = fieldsProps => {
	if (!fieldsProps) {
		return null;
	}

	const fields = [
		fieldsProps.photoURL,
		fieldsProps.firstName,
		fieldsProps.lastName,
		fieldsProps.username,
		fieldsProps.email,
		fieldsProps.email && fieldsProps.emailVerified,
	];

	let profileCompletion = 0;

	fields.forEach(field => {
		if (field) {
			profileCompletion += 100 / fields.length;
		}
	});

	return Math.floor(profileCompletion);
};

authentication.getSecurityRating = (user, userData) => {
	if (!user || !user.metadata) {
		return null;
	}

	let {creationTime} = user.metadata;

	if (!creationTime) {
		return null;
	}

	creationTime = moment(creationTime);

	let securityRating = 0;

	if (userData && userData.lastPasswordChange) {
		let {lastPasswordChange} = userData;

		if (lastPasswordChange) {
			lastPasswordChange = moment(lastPasswordChange.toDate());

			if (creationTime.diff(lastPasswordChange, 'days') >= 365.242199) {
				securityRating = 50;
			} else {
				securityRating = 100;
			}
		}
	} else if (moment().diff(creationTime, 'days') >= 365.242199) {
		securityRating = 50;
	} else {
		securityRating = 100;
	}

	return securityRating;
};

authentication.getErrorFirebase = errors.getErrorFirebase;
export default authentication;
