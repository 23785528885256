import {createTheme} from '@material-ui/core';

const themeStyle = {
	breakpoints: {
		xs: 0,
		sm: 600,
		md: 1024,
		lg: 1280,
		xl: 1920,
	},
	spacing: {
		0: '0',
		1: '0.25rem',
		2: '0.5rem',
		3: '1rem',
		4: '1.5rem',
		5: '3rem',
	},
	color: {
		primary: '#ffffff',
		secundary: '#1b1b1b',
		themePrimary: '#091c3a',
		themeSecundary: '#103770',
		themeTerciary: '#308BCB',
		themeQuaternary: '#a9bee2',
		secundaryRGBA: 'rgba(0, 0, 0, 0.87)',
		grayDark: '#363636',
		grayBackground: '#F2F2F2',
		greenBackground: 'rgba(36, 159, 132, 0.2)',
		pinkBackground: '#FFB5B5',
		error: '#BE3232',
		success: '#249F84',
		neutral: '#363636',
		warning: '#ff9800',
		default: '#bdbdbd',
		paper: '#f0f0f0',
		neturalLowMedium: '#818181',
	},
};

export const CustomMaterialTheme = () =>
	createTheme({
		palette: {
			grayDark: {
				main: themeStyle.color.grayDark,
			},
			primary: {
				main: themeStyle.color.themeSecundary,
			},
			terciary: {
				main: themeStyle.color.themeTerciary,
			},
			secondary: {
				main: themeStyle.color.error,
			},
			success: {
				main: themeStyle.color.success,
			},
			error: {
				main: themeStyle.color.error,
			},
		},
		typography: {
			fontFamily: ['Source Sans Pro', 'sans-serif'],
		},
	});

export default themeStyle;
