import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from 'react-redux';
import * as Styled from './styled';

// eslint-disable-next-line react/prop-types
function Loading({loading = false}) {
	return (
		<Styled.Loading loading={loading.toString()}>
			<LinearProgress color="secondary" style={{width: '100%'}} />
		</Styled.Loading>
	);
}

const mapStateToProps = state => {
	return {
		loading: state?.Loading?.loading || state?.operators?.isLoading,
	};
};

export default connect(mapStateToProps)(Loading);
