/* eslint-disable import/no-cycle */
import axios from 'axios';
import toastr from 'services/Alerts';
import { getTokenAuth } from '../services/ProviderAuth/service/Auth.services';

let retry = false;
/**
 * Call API's using Axios and JWT Token current user on Firebase.
 * When token is expired axios use request token to continue with request original
 * @requires
 * @param String baseUrl
 * @param String url
 * @optional
 * @param String method
 * @param Boolean not_Headers
 * @param Object body
 * @param Object headers
 * @param Function errorCallback
 */
export const requestWithRefreshToken = async (
	baseUrl,
	url,
	method = 'get',
	notHeaders = false,
	body = false,
	headers = {},
	errorCallback = () => { },
	responseType = '',
) => {
	if (window.inactive) {
		throw new Error('Sistema inativo. Aguardando atividade do operador.');
	}

	// Template instance
	const setInstanceToken = finalToken => {
		const _headers = notHeaders
			? ''
			: {
				...headers,
				Authorization: `Bearer ${String(finalToken).replace(/"/g, '')}`,
				'Content-Type': 'application/json',
			};
		const config = {
			baseURL: baseUrl,
			headers: { ..._headers },
			responseType,
		};
		if (method === 'get' && body) {
			config.params = body;
		}
		const instance = axios.create(config);
		instance.interceptors.response.use(null, async error => {
			if (
				!error.message.includes('Sistema inativo.') &&
				!error.message.includes('Error: A network error') &&
				!window.inactive
			) {
				if (
					!retry &&
					error.config &&
					(error.response?.status === 401 || error.response?.status === 403)
				) {
					retry = true;
					const { config: c } = error;
					const newToken = await getTokenAuth();
					c.headers.Authorization = `Bearer ${newToken}`;
					return instance.request(c);
				}

				retry = false;
				await toastr.report(error, {
					additionalInformation: {
						status: error.response?.status || '',
						headers: error.config.headers,
						method: error.config.method,
						url: error.config.url,
						data: error.config.data,
					},
				});
			}

			return Promise.reject(
				error || error.response?.data?.message || error.message,
			);
		});
		return instance({
			method,
			data: body || {},
			url
		});
	};

	try {
		const token = await getTokenAuth();
		return setInstanceToken(token);
	} catch (error) {
		errorCallback(error);
		throw error;
	}
};

export default requestWithRefreshToken;
