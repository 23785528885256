import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';

function GridContainer(props) {
	const {classes, children} = props;
	return (
		<Grid container className={classes.root} spacing={16}>
			{children}
		</Grid>
	);
}

GridContainer.defaultProps = {
	children: null,
};

GridContainer.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.element,
};

export default withStyles(styles)(GridContainer);
