import React, {memo} from 'react';
import {Typography} from '@material-ui/core';
import GridContainer from 'common/Grid';
import {useAuth} from 'services/ProviderAuth/ProviderAuth';

function Dashboard() {
	const {user} = useAuth();
	return (
		<GridContainer>
			<Typography variant="h6">
				Seja bem vindo, <strong>{user?.name}</strong>
			</Typography>
		</GridContainer>
	);
}

export default memo(Dashboard);
